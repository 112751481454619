<br>
<div class="col-md-12">
    <div class="d-flex flex-row" style="justify-content:center;">
        <div class="col-sm-4">
            <form [formGroup]="headquarterInfo">
                <h4><strong> Headquarters Location</strong></h4>
                <div class="d-flex" style="padding: 2px;margin-top:2%;">
                    <label style="width: 50%;" class="required">Company Name</label>
                    <input bs-tooltip title="Please Enter Company Name" type="text" class="form-control"
                        formControlName="companyName">
                </div>
                <div style="display: flex;">
                    <label class="errwidth"></label>
                    <div *ngIf="headquarterInfo.controls['companyName'].invalid && headquarterInfo.controls['companyName'].touched"
                        class="alert alert-danger ">
                        <div *ngIf="headquarterInfo.controls['companyName'].errors.required">
                            Please Enter Company Name
                        </div>
                    </div>
                </div>
                <div class="d-flex" style="padding: 2px;">
                    <label style="width: 50%;" class="required">Address Line 1</label>
                    <input bs-tooltip title="Please Enter Address" type="text" class="form-control"
                        formControlName="address1">
                </div>
                <div style="display: flex;">
                    <label class="errwidth"></label>
                    <div *ngIf="headquarterInfo.controls['address1'].invalid && headquarterInfo.controls['companyName'].touched"
                        class="alert alert-danger">
                        <div *ngIf="headquarterInfo.controls['address1'].errors.required">
                            Please Enter Addres Name
                        </div>
                    </div>
                </div>
                <div class="d-flex" style="padding: 2px;">
                    <label style="width: 50%;">Address Line 2</label>
                    <input type="text" class="form-control" formControlName="address2" bs-tooltip
                        title="Please Enter Address Line 2, if applicable">
                </div>
                <div class="d-flex">
                    <label></label>
                </div>
                <div class="d-flex" style="padding: 2px !important">
                    <label style="width: 50%;" class="required">City</label>
                    <input type="text" class="form-control" formControlName="city" bs-tooltip
                        title="Please Enter City Name">
                </div>
                <div style="display: flex;">
                    <label class="errwidth"></label>
                    <div *ngIf="headquarterInfo.controls['city'].invalid && headquarterInfo.controls['city'].touched"
                        class="alert alert-danger">
                        <div *ngIf="headquarterInfo.controls['city'].errors.required">
                            Please Enter city
                        </div>
                    </div>
                </div>

                <div class="d-flex" style="padding: 2px;">
                    <label style="width: 50%;" class="required"> Country</label>
               <ng-multiselect-dropdown  formControlName="country" (click)="countryChange()" class=" dropdown1"[settings]="dropdownCountrySettings" [data]="countryListOther"></ng-multiselect-dropdown>

                </div>
                <div style="display: flex;">
                    <label class="errwidth"></label>
                    <div *ngIf="headquarterInfo.controls['country'].invalid && headquarterInfo.controls['country'].touched"
                        class="alert alert-danger">
                        <div *ngIf="headquarterInfo.controls['country'].errors.required">
                            Please Enter Country
                        </div>
                    </div>
                </div>
                <div class="d-flex" style="padding: 2px;">
                    <label style="width: 50%;" class="required">State/Province</label>

                    <div style="width: -webkit-fill-available">
                        
                        <ng-multiselect-dropdown  formControlName="state" (click)="changeState(headquarterInfo.controls.state.value)" class=" dropdown1"[settings]="dropdownSettings" [data]="stateListOther"></ng-multiselect-dropdown>

                        <input type="text" style="margin-top: 5px;"
                            *ngIf="state == 'Other'" class="form-control"
                            formControlName="otherState" id="other_State" name="otherState" bs-tooltip
                            title="Please Enter the State/Province">
                    </div>
                </div>
                <div style="display: flex;">
                    <label class="errwidth"></label>
                    <div *ngIf="headquarterInfo.controls['state'].invalid && headquarterInfo.controls['state'].touched"
                        class="alert alert-danger">
                        <div *ngIf="headquarterInfo.controls['state'].errors.required">
                            Please Select State
                        </div>
                    </div>
                    <div *ngIf="headquarterInfo.controls['otherState'].invalid && headquarterInfo.controls['otherState'].touched"
                        class="alert alert-danger">
                        <div *ngIf="headquarterInfo.controls['otherState'].errors.required">
                            Please Enter State
                        </div>
                    </div>
                </div>
                <div class="d-flex" style="padding: 2px;">
                    <label style="width: 50%;" class="required">Postal Code</label>
                    <input type="text" required bs-tooltip title="Please Enter Postal Code" class="form-control"
                        formControlName="postalCode">
                </div>
                <div style="display: flex;">
                    <label class="errwidth"></label>
                    <div *ngIf="headquarterInfo.controls['postalCode'].invalid && headquarterInfo.controls['postalCode'].touched"
                        class="alert alert-danger">
                        <div *ngIf="headquarterInfo.controls['postalCode'].errors.required">
                            Please Enter Postal Code
                        </div>
                    </div>
                </div>
                <div class="d-flex" style="padding: 2px;">
                    <label style="width: 50%;" class="required">Tax ID Number</label>
                    <input type="text" bs-tooltip title="Please Enter Tax ID" class="form-control"
                        formControlName="taxID">
                </div>
                <div style="display: flex;">
                    <label class="errwidth"></label>
                    <div *ngIf="headquarterInfo.controls['taxID'].invalid && headquarterInfo.controls['taxID'].touched"
                        class="alert alert-danger">
                        <div *ngIf="headquarterInfo.controls['taxID'].errors.required">
                            Please Enter Tax ID
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div style="border-left-style: dotted;width:0%;padding-right: 10px;padding-left: 10px;">

        </div>
        <div class="col-sm-4">
            <form [formGroup]="salesInfo">
                <h4><strong>Sales Contact Information</strong></h4>
                <div class="d-flex" style="padding: 2px;margin-top:2%;">
                    <label style="width: 50%;" class="required"> Name</label>
                    <input bs-tooltip title="Please Enter Sales Person Name" type="text" class="form-control"
                        formControlName="name">
                </div>
                <div style="display: flex;">
                    <label class="errwidth"></label>
                    <div *ngIf="salesInfo.controls['name'].invalid && salesInfo.controls['name'].touched"
                        class="alert alert-danger">
                        <div *ngIf="salesInfo.controls['name'].errors.required">
                            Please Enter Name
                        </div>
                    </div>
                </div>
                <div class="d-flex" style="padding: 2px;">
                    <label style="width: 50%;" class="required">Title</label>
                    <input type="text" bs-tooltip title="Please Enter Sales Person Title" class="form-control"
                        formControlName="title">
                </div>
                <div style="display: flex;">
                    <label class="errwidth"></label>
                    <div *ngIf="salesInfo.controls['title'].invalid && salesInfo.controls['title'].touched"
                        class="alert alert-danger">
                        <div *ngIf="salesInfo.controls['title'].errors.required">
                            Please Enter Title
                        </div>
                    </div>
                </div>
                <div class="d-flex" style="padding: 2px;">
                    <label style="width: 50%;" class="required">Phone</label>
                    <input type="text" bs-tooltip title="Please Enter Sales Person Phone" [maxLength]="13"
                        (change)="onInputChange($event)" class="form-control " formControlName="phoneNo">
                </div>
                <div style="display: flex;">
                    <label class="errwidth"></label>
                    <div *ngIf="salesInfo.controls['phoneNo'].invalid && salesInfo.controls['phoneNo'].touched"
                        class="alert alert-danger">
                        <div *ngIf="salesInfo.controls['phoneNo'].errors.required">
                            Please Enter Phone
                        </div>
                    </div>
                </div>
                <div class="d-flex" style="padding: 2px;">
                    <label style="width: 50%;" class="required">Email</label>
                    <input type="text" class="form-control" (change)="checkEmail()"
                        pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9._-]+\.[A-Za-z]{2,4}$" formControlName="email" bs-tooltip
                        title="Please Enter Sales Person Email">
                </div>
                <div style="display: flex">
                    <label class="errwidth"></label>
                    <div *ngIf="salesInfo.controls['email'].invalid && salesInfo.controls['email'].touched">
                        <div *ngIf="salesInfo.controls['email'].errors.pattern" class="alert alert-danger">
                            Please Enter Valid Email
                        </div>
                        <div *ngIf="salesInfo.controls['email'].errors.required" class="alert alert-danger">
                            Please Enter Email
                        </div>

                    </div>

                </div>
                <div class="d-flex" style="padding: 2px;">
                    <label style="width: 50%;" class="required">Confirm Email</label>
                    <input bs-tooltip title="Please Reconfirm Sales Person Email" type="text" class="form-control"
                        formControlName="confirmEmail" (change)="checkEmail()">
                </div>
                <div *ngIf="errEmail == true">
                    <label class="errwidth"></label>
                    <label class="alert alert-danger">Email is not match</label>
                </div>
                <div style="display: flex;">
                    <label class="errwidth"></label>
                    <div
                        *ngIf="salesInfo.controls['confirmEmail'].invalid && salesInfo.controls['confirmEmail'].touched">
                        <div *ngIf="salesInfo.controls['confirmEmail'].errors.pattern" class="alert alert-danger">
                            Please Enter Valid Email
                        </div>
                        <div *ngIf="salesInfo.controls['confirmEmail'].errors.required" class="alert alert-danger">
                            Please Enter Email
                        </div>
                    </div>
                </div>
                <div class="d-flex" style="padding: 2px;">
                    <label style="width: 50%;" class="required">Website</label>
                    <input type="text" bs-tooltip title="Please Enter Website URL" class="form-control"
                        formControlName="website">
                </div>
                <div style="display: flex;">
                    <label class="errwidth"></label>
                    <div *ngIf="salesInfo.controls['website'].invalid && salesInfo.controls['website'].touched"
                        class="alert alert-danger">
                        <div *ngIf="salesInfo.controls['website'].errors.required">
                            Please Enter Website
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="col-md-12 " style="padding-left: 2%;">
    <h4><strong>Other Locations</strong></h4>
    <p class="required" style="padding-left:2%;">
        Please select all applicable locations of the plants or parts/service providers associated with the company.</p>
    <div *ngIf="locationErr == true">
        <label class="alert alert-danger">Select at least one location</label>
    </div>
    <div class="col-md-12">
        <div class="d-flex">
            <div class="col-sm-6">
                <div class="d-flex btn-checkbox">
                    <div>
                        <img border="0" src="../../assets/img/usmap.png" width="300px" height="200px">
                    </div>
                    <div>
                        <div *ngFor="let data of checkboxList;let i=index">

                            <input name="location" id="location" type="checkbox"
                                (change)="data.isSelected = !data.isSelected" [checked]="data.isSelected"
                                [value]="data.value">&nbsp;{{data.name}}

                        </div>
                    </div>

                </div>
            </div>

            <div class="col-sm-6">
                <div class="d-flex btn-checkbox">
                    <div>
                        <img border="0" src="../../assets/img/canada.png" width="300px" height="200px">
                    </div>
                    <div>
                        <div *ngFor="let data of checkboxList3;let i=index">

                            <input name="location" id="location" type="checkbox" [value]="data.value"
                                (change)="data.isSelected = !data.isSelected"
                                [checked]="data.isSelected">&nbsp;{{data.name}}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="col-md-12">
        <div class="d-flex">
            <div class="col-sm-6">
                <div class="d-flex btn-checkbox">
                    <div>
                        <img border="0" src="../../assets/img/mexico-states-map.jpg" width="300px" height="200px">
                    </div>
                    <div>
                        <div *ngFor="let data of checkboxList2;let i=index">

                            <input name="location" id="location" type="checkbox" [value]="data.value"
                                (change)="data.isSelected = !data.isSelected"
                                [checked]="data.isSelected">&nbsp;{{data.name}}

                        </div>
                    </div>

                </div>
            </div>

            <div class="col-sm-6" style="padding-top: 2% ;">
                <div class="d-flex btn-checkbox">

                    <div>
                        <label>Other Continents :</label>
                    </div>
                    <label style="padding:4%"> </label>
                    <div>

                        <div *ngFor="let data of checkboxList4;let i=index">

                            <input name="location" id="location" type="checkbox" [value]="data.value"
                                (change)="data.isSelected = !data.isSelected"
                                [checked]="data.isSelected">&nbsp;{{data.name}}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>