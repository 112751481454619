import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { LookupService } from '../services/lookup.service';
import { RegistrationService } from '../services/registration.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgPlural } from '@angular/common';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-product-group',
  templateUrl: './product_Group.component.html',
  styleUrls: ['./product_Group.component.scss']
})
export class ProductGroupComponent implements OnInit {
  productForm: UntypedFormGroup;
  duns_number = sessionStorage.getItem('duns_number');
  email = sessionStorage.getItem('email');
  poductList: any;
  productList: any;
  submitValue: boolean;
  successMessage: string = '';
  failureAlert: boolean = false;
  successAlert: boolean = false;
  failureMessage: string;
  dropdownSettings: IDropdownSettings = {};
  primary_id: any = [];

  constructor(private formBuilder: UntypedFormBuilder, private registrationService: RegistrationService, private lookupService: LookupService, private ngxSpinner: NgxSpinnerService) {
    this.productForm = this.formBuilder.group({});
    this.productForm = new UntypedFormGroup({
      primary_product_id: new UntypedFormControl('', [Validators.required]),
      secondary_product_id: new UntypedFormControl('', []),
      tertiary_product_id: new UntypedFormControl('', []),
      primary_product_notes: new UntypedFormControl('', []),
      secondary_product_notes: new UntypedFormControl('', []),
      tertiary_product_notes: new UntypedFormControl('', [])
    });

    this.lookupService.getProductList().subscribe(data1 => {

      let productList: any = data1;
      this.productList = productList.body;

    });
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'commodity_code',
      textField: 'commodity_name',
      enableCheckAll: false,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      limitSelection: 1,
      searchPlaceholderText: 'Select'
    };
    setTimeout(() => {
      this.registrationService.getProductGroup(this.duns_number).subscribe(data => {
        if (data.statusCode == 200) {
          let tertiary_product = [];
          let secondary_product = [];

          this.productForm.controls.primary_product_notes.setValue(data.body[0].primary_product_notes);
          this.productForm.controls.secondary_product_notes.setValue(data.body[0].secondary_product_notes);
          this.productForm.controls.tertiary_product_notes.setValue(data.body[0].tertiary_product_notes);
          this.productList.forEach(item => {
            if (item.commodity_code == data.body[0].primary_product_id) {
              item.textField = item.commodity_name;
              item.idField = item.commodity_code;
              this.primary_id.push(item);

            }
            if (item.commodity_code == data.body[0].secondary_product_id) {

              item.textField = item.commodity_name;
              item.idField = item.commodity_code;
              secondary_product.push(item);

            }
            if (item.commodity_code == data.body[0].tertiary_product_id) {

              item.textField = item.commodity_name;
              item.idField = item.commodity_code;
              tertiary_product.push(item);

            }
          })
          this.productForm.controls.primary_product_id.setValue(this.primary_id)
          this.productForm.controls.secondary_product_id.setValue(secondary_product);
          this.productForm.controls.tertiary_product_id.setValue(tertiary_product);
        }
      });
    }, 500);
  }


  saveProduct() {
    let data: any = {};
    this.submitValue = false;
    debugger;
    var today = new Date();
    this.productForm.markAllAsTouched();
    if (this.productForm.valid) {
      this.ngxSpinner.show();
      data.duns_number = this.duns_number;
      data.primary_product_id = null;
      data.primary_product_notes = this.productForm.controls.primary_product_notes.value;
      this.productList.forEach(item => {
        if (this.productForm.controls.primary_product_id.value.length > 0) {
          if (item.commodity_code == this.productForm.controls.primary_product_id.value[0].commodity_code) {
            data.primary_product_name = item.commodity_name;
            data.primary_product_id = item.commodity_code;
          }
        }
      })
      data.secondary_product_id = null;
      this.productList.forEach(item => {
        if (this.productForm.controls.secondary_product_id.value.length > 0) {
          if (item.commodity_code == this.productForm.controls.secondary_product_id.value[0].commodity_code) {
            data.secondary_product_name = item.commodity_name;
            data.secondary_product_id = item.commodity_code;
          }
        }
      })
      data.secondary_product_notes = this.productForm.controls.secondary_product_notes.value;
      data.tertiary_product_id = null;
      data.tertiary_product_notes = this.productForm.controls.tertiary_product_notes.value;
      this.productList.forEach(item => {
        if (this.productForm.controls.tertiary_product_id.value.length > 0) {
          if (item.commodity_code == this.productForm.controls.tertiary_product_id.value[0].commodity_code) {
            data.tertiary_product_name = item.commodity_name;
            data.tertiary_product_id = item.commodity_code;
          }
        }
      })
      data.created_by = this.email;
      this.registrationService.insertProductGroup(data).subscribe(data => {

        if (data["statusCode"] === 201 || data["statusCode"] === 200) {
          this.submitValue = true;


        } else {
          this.submitValue = false;
        }

      }, (error) => {
        console.log(error);
        this.submitValue = false;
        this.failureAlert = true;
        this.ngxSpinner.hide();
        this.failureMessage = "Something went wrong. Please try after sometime.";
        setTimeout(() => {
          this.failureAlert = false;
          this.failureMessage = '';
        }, 3000);
      })

    }

  }
}
