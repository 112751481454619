import { Component, HostListener, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  
  newClickLink: boolean = false;
  isCloseClicked: boolean = false;
  selectedAllfunctionComp: boolean = false;
  isAdmin: boolean = false;
  isFloorUser: boolean = false;
  // flag:boolean=false;
  staticRole: boolean = false;
  givenName: string = "";
  familyName: string = "";
  path = '';
  loginRole = '';
  loadPopUp: boolean;
  title = 'psi';
  showHeaderFooter = true
  public innerWidth: any;
  loggedUserGuid: string = '';
  userInactive: Subject<any> = new Subject();
  userActivity: any;
  role: any;
  userName: any;
  account: any;
  name: any = 'psi Local';
 

  encryptedText: any;
  encryptedText_scanIn: any;
  encryptedText_scanOut: any;
  encryptedText_summary: any;

  constructor(private location: Location, private router: Router,  
 
    private route: ActivatedRoute , private spinner: NgxSpinnerService) {
   
    // Uncomment line number 66 when working in local
    // this.sharedservice.send_Role({ isAdmin: true, isFloorUser: false, role: 'ISAdmin', userName: 'psi.local@tmnatest.com', name: 'psi Local' })

    this.router.events.subscribe((val) => {
      this.path = this.location.path();

    });

    this.userInactive.subscribe(() => {
      localStorage.clear();
      sessionStorage.clear();
      this.logout();

    }
    );
  }

  ngOnInit() {
   
  }

  setLoggedUser(data) {
   
  }

  logout() {
    //this.authenticationService.logout();
  }

  setTimeout() {
    clearTimeout;
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 1800000)
  }

  @HostListener('document:keypress') refreshUserState2() {
    clearTimeout(this.userActivity);
    this.setTimeout();

  }

  @HostListener('document:click') refreshUserState3() {
    clearTimeout(this.userActivity);
    this.setTimeout();


  }

  @HostListener('mousewheel') refreshUserState4() {
    clearTimeout(this.userActivity);
    this.setTimeout();

  }

  ngOnDestroy() {
    sessionStorage.clear();
  }
}

