<div style="min-height: 79vh;" *ngIf="this.duns_number != null && this.duns_number != '' && this.duns_number != undefined">
    <mat-tab-group class="mat-tab-group-head content" #partstructuretab animationDuration="0ms"
        (selectedIndex)="selectedTabIndex" [selectedIndex]="selectedIndex" (selectedTabChange)="onTabChanged($event)">
        <mat-tab class="mat-tab-element-2" label="Company Information">
            <app-company-info></app-company-info>
            <br>
            <div class="col-sm-8">
                <p style="font-size: 10px;">
                    ©2023 Toyota Motor Engineering & Manufacturing North America. Permission is granted to copy,
                    distribute
                    and/or modify this document under the terms of the GNU Free Documentation License, Version 1.2 or
                    any
                    later version published by the Free Software Foundation; with no Invariant Sections, no Front-Cover
                    Texts, and no Back-Cover Texts. A copy of the license is included in the section entitled "GNU Free
                    Documentation License
                </p>
            </div>
        </mat-tab>
        <mat-tab class="mat-tab-element-1" label="Product Group">
            <app-product-group></app-product-group>
        </mat-tab>
        <mat-tab class="mat-tab-element-1" label="Safety/Quality/Delivery">
            <app-safety-delivery></app-safety-delivery>
        </mat-tab>
        <mat-tab class="mat-tab-element-1" label="Regulatory">
            <app-regulatory></app-regulatory>
        </mat-tab>
        <mat-tab class="mat-tab-element-1" label="Company Profile">
            <app-companyProfile></app-companyProfile>
        </mat-tab>
    </mat-tab-group>

</div>
<div class="reg-footer btn-footer d-flex justify-content-end" style="
justify-content: space-between !important;
">
    <div class="align-self-center cpny">
        <span >
            For technical assistance, please contact our centralized help desk at 1-855-349-9898 or e-mail
            IS_Service_Desk&#64;toyota.com </span> <br>
        <span > ©2023 Toyota Motor Engineering & Manufacturing North America</span>
       
    </div>
    <div class="d-flex flex-row btn-button" >
        <button id="btn-previous" class="button btn pr-btn" [disabled]="selectedIndex == 0"
            (click)="prePage()">Previous</button>
        <button id="btn-save" class="button btn pr-btn" [disabled]=" selectedIndex == 4" (click)="nextPage()"> Save &
            Proceed</button>

        <button id="btn-finish" class="buttonFinish btn pr-btn" [disabled]="selectedIndex != 4 || disable"
            (click)="finishURL()">Finish</button>
        <br>
        <br>
    </div>
</div>