import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegistrationService } from '../services/registration.service';

@Component({
  selector: 'app-safety-delivery',
  templateUrl: './safetyQualityDelivery.component.html',
  styleUrls: ['./safetyQualityDelivery.component.scss']
})
export class SafetyQualityDeliveryComponent implements OnInit {

  total_incident = `{(#of incidents x 200,000)/# of employee worked hours}`;
  total_internal = `{(# of defects/# of pieces made) x 1,000,000}`;
  total_external = `{(# of defects/# of pieces shipped) x 1,000,000}`;
  sqdForm: UntypedFormGroup;
  duns_number = sessionStorage.getItem('duns_number');
  email = sessionStorage.getItem('email');
  submitValue: boolean;
  successMessage: string = '';
  failureAlert: boolean = false;
  successAlert: boolean = false;
  failureMessage: string;

  constructor(private formBuilder: UntypedFormBuilder, private registrationService: RegistrationService, private ngxSpinner: NgxSpinnerService) {

    this.sqdForm = this.formBuilder.group({});
    this.sqdForm = new UntypedFormGroup({
      naics: new UntypedFormControl('', []),
      fatalities_number: new UntypedFormControl('', []),
      total_incident_rate: new UntypedFormControl('', []),
      incidents_per_year: new UntypedFormControl('', []),
      employee_hrs_per_year: new UntypedFormControl('', []),
      track_safety_metrics: new UntypedFormControl('', []),
      industry_avg_incident_rate: new UntypedFormControl('', []),
      defects_per_year: new UntypedFormControl('', []),
      pieces_made_per_year: new UntypedFormControl('', []),
      internal_parts_per_million: new UntypedFormControl('', []),
      ext_incidents_per_year: new UntypedFormControl('', []),
      pieces_shipped_per_year: new UntypedFormControl('', []),
      external_parts_per_million: new UntypedFormControl('', []),
      track_quality_metrics: new UntypedFormControl('', []),
      ontime_delivery_percentage: new UntypedFormControl('', []),
      track_delivery_metrics: new UntypedFormControl('', [])
    });
  }

  ngOnInit(): void {
    
    this.registrationService.getSQDGroup(this.duns_number).subscribe(data => {
      if (data.statusCode == 200) {
        this.sqdForm.controls.naics.setValue(data.body[0].naics)
        this.sqdForm.controls.fatalities_number.setValue(data.body[0].fatalities_number);
        this.sqdForm.controls.total_incident_rate.setValue(data.body[0].total_incident_rate);
        this.sqdForm.controls.incidents_per_year.setValue(data.body[0].incidents_per_year);
        this.sqdForm.controls.employee_hrs_per_year.setValue(data.body[0].employee_hrs_per_year);

        this.sqdForm.controls.industry_avg_incident_rate.setValue(data.body[0].industry_avg_incident_rate)
        this.sqdForm.controls.defects_per_year.setValue(data.body[0].defects_per_year);
        this.sqdForm.controls.pieces_made_per_year.setValue(data.body[0].pieces_made_per_year);
        this.sqdForm.controls.incidents_per_year.setValue(data.body[0].incidents_per_year);
        this.sqdForm.controls.internal_parts_per_million.setValue(data.body[0].internal_parts_per_million);
        this.sqdForm.controls.ext_incidents_per_year.setValue(data.body[0].ext_incidents_per_year);

        this.sqdForm.controls.pieces_shipped_per_year.setValue(data.body[0].pieces_shipped_per_year)
        this.sqdForm.controls.external_parts_per_million.setValue(data.body[0].external_parts_per_million);

        this.sqdForm.controls.ontime_delivery_percentage.setValue(data.body[0].ontime_delivery_percentage);
        this.sqdForm.controls.ext_incidents_per_year.setValue(data.body[0].ext_incidents_per_year);
        if (data.body[0].track_delivery_metrics == 'Y')
          this.sqdForm.controls.track_delivery_metrics.setValue(true);
        else
          this.sqdForm.controls.track_delivery_metrics.setValue('');

        if (data.body[0].track_quality_metrics == 'Y')
          this.sqdForm.controls.track_quality_metrics.setValue(true);
        else
          this.sqdForm.controls.track_quality_metrics.setValue('');

        if (data.body[0].track_safety_metrics == 'Y')
          this.sqdForm.controls.track_safety_metrics.setValue(true);
        else
          this.sqdForm.controls.track_safety_metrics.setValue('');
      }
    });
  }

  saveSQD() {
    this.submitValue = false;
    let data: any = {};
    this.sqdForm.markAllAsTouched();
    if (this.sqdForm.valid) {
      this.ngxSpinner.show();
      data.duns_number = this.duns_number;
      if(this.sqdForm.controls.naics.value != '' && this.sqdForm.controls.naics.value != undefined)
      data.naics = this.sqdForm.controls.naics.value;
      else{
        data.naics = null;
      }
      if(this.sqdForm.controls.fatalities_number.value != '' && this.sqdForm.controls.fatalities_number.value != undefined)
      data.fatalities_number = this.sqdForm.controls.fatalities_number.value;
      else{
        data.fatalities_number = null;
      }
      if(this.sqdForm.controls.total_incident_rate.value != "Infinity" && this.sqdForm.controls.total_incident_rate.value != '')
      data.total_incident_rate = this.sqdForm.controls.total_incident_rate.value;
      else{
        data.total_incident_rate = null; 
      }
      if(this.sqdForm.controls.incidents_per_year.value != '' && this.sqdForm.controls.incidents_per_year.value != undefined)
      data.incidents_per_year = this.sqdForm.controls.incidents_per_year.value;
      else{
        data.incidents_per_year = null;
      }
      if(this.sqdForm.controls.employee_hrs_per_year.value != '' && this.sqdForm.controls.employee_hrs_per_year.value != undefined)
      data.employee_hrs_per_year = this.sqdForm.controls.employee_hrs_per_year.value;
else{
  data.employee_hrs_per_year = null;
}
if(this.sqdForm.controls.industry_avg_incident_rate.value != '' && this.sqdForm.controls.industry_avg_incident_rate.value != undefined)
      data.industry_avg_incident_rate = this.sqdForm.controls.industry_avg_incident_rate.value;
      else{
        data.industry_avg_incident_rate = null;
      }
      if(this.sqdForm.controls.defects_per_year.value != '' && this.sqdForm.controls.defects_per_year.value != undefined)
      data.defects_per_year = this.sqdForm.controls.defects_per_year.value;
      else{
        data.defects_per_year = null;
      }
      if(this.sqdForm.controls.pieces_made_per_year.value != '')
      data.pieces_made_per_year = this.sqdForm.controls.pieces_made_per_year.value;
else{
  data.pieces_made_per_year =  null;
}
      if(this.sqdForm.controls.internal_parts_per_million.value != '')
      data.internal_parts_per_million = this.sqdForm.controls.internal_parts_per_million.value;
      else{
        data.internal_parts_per_million = null; 
      }
      if(this.sqdForm.controls.ext_incidents_per_year.value != '' && this.sqdForm.controls.ext_incidents_per_year.value != undefined)
      data.ext_incidents_per_year = this.sqdForm.controls.ext_incidents_per_year.value;
else{
  data.ext_incidents_per_year =  null;
}
if(this.sqdForm.controls.pieces_shipped_per_year.value != '' && this.sqdForm.controls.pieces_shipped_per_year.value != undefined)
      data.pieces_shipped_per_year = this.sqdForm.controls.pieces_shipped_per_year.value;
      else{
        data.pieces_shipped_per_year = null; 
      }
      if(this.sqdForm.controls.external_parts_per_million.value != null && this.sqdForm.controls.external_parts_per_million.value !='')
      data.external_parts_per_million = this.sqdForm.controls.external_parts_per_million.value;
      else{
        data.external_parts_per_million =null;  
      }
      if( this.sqdForm.controls.ontime_delivery_percentage.value != '' &&  this.sqdForm.controls.ontime_delivery_percentage.value != undefined)
      data.ontime_delivery_percentage = this.sqdForm.controls.ontime_delivery_percentage.value;
      else{
        data.ontime_delivery_percentage = null;
      }
      if( this.sqdForm.controls.ext_incidents_per_year.value != '' &&  this.sqdForm.controls.ext_incidents_per_year.value != undefined)
      data.ext_incidents_per_year = this.sqdForm.controls.ext_incidents_per_year.value;
      else{
        data.ext_incidents_per_year = null; 
      }
      if (this.sqdForm.controls.track_delivery_metrics.value)
        data.track_delivery_metrics = 'Y';
      else
        data.track_delivery_metrics = 'N';

      if (this.sqdForm.controls.track_quality_metrics.value)
        data.track_quality_metrics = 'Y';
      else
        data.track_quality_metrics = 'N';

      if (this.sqdForm.controls.track_safety_metrics.value)
        data.track_safety_metrics = 'Y';
      else
        data.track_safety_metrics = 'N';
      data.created_by = this.email;
      this.registrationService.insertSQDGroup(data).subscribe(data => {
        if (data["statusCode"] === 201 || data["statusCode"] === 200) {
          this.submitValue = true;
          this.ngOnInit();
        }
        

      }, (error)=>{
        console.log(error);
        this.submitValue = false;
        this.failureAlert = true;
        this.ngxSpinner.hide();
        this.ngOnInit();
        this.failureMessage = "Something went wrong. Please try after sometime.";
        setTimeout(() => {
          this.failureAlert = false;
          this.failureMessage = '';
        }, 3000);
      })
     
    }
  }

  totalIncidentRate() {
debugger;
    if (this.sqdForm.controls.incidents_per_year.value != '' && this.sqdForm.controls.employee_hrs_per_year.value != ''
      && this.sqdForm.controls.incidents_per_year.value != 0 && this.sqdForm.controls.employee_hrs_per_year.value != 0
      && this.sqdForm.controls.incidents_per_year.value != '.' && this.sqdForm.controls.employee_hrs_per_year.value != '.'
      && this.sqdForm.controls.incidents_per_year.value != null && this.sqdForm.controls.employee_hrs_per_year.value != null
      && this.sqdForm.controls.incidents_per_year.value != undefined && this.sqdForm.controls.employee_hrs_per_year.value != undefined) {
      this.sqdForm.controls.total_incident_rate.setValue((this.sqdForm.controls.incidents_per_year.value * 200000 / this.sqdForm.controls.employee_hrs_per_year.value).toFixed(1));

    } else {
      this.sqdForm.controls.total_incident_rate.setValue('');
    }
  }
  internal_parts_per_million() {
    if (this.sqdForm.controls.defects_per_year.value != '' && this.sqdForm.controls.pieces_made_per_year.value != ''
      && this.sqdForm.controls.defects_per_year.value != 0 && this.sqdForm.controls.pieces_made_per_year.value != 0
      && this.sqdForm.controls.defects_per_year.value != '.' && this.sqdForm.controls.pieces_made_per_year.value != '.'
      && this.sqdForm.controls.defects_per_year.value != null && this.sqdForm.controls.pieces_made_per_year.value != null
      && this.sqdForm.controls.defects_per_year.value != undefined && this.sqdForm.controls.pieces_made_per_year.value != undefined) {
      this.sqdForm.controls.internal_parts_per_million.setValue(((this.sqdForm.controls.defects_per_year.value / this.sqdForm.controls.pieces_made_per_year.value) * 1000000).toFixed(1));

    } else {
      this.sqdForm.controls.internal_parts_per_million.setValue('');
    }
  }
  external_parts_per_million() {
    if (this.sqdForm.controls.ext_incidents_per_year.value != '' && this.sqdForm.controls.pieces_shipped_per_year.value != ''
      && this.sqdForm.controls.ext_incidents_per_year.value != 0 && this.sqdForm.controls.pieces_shipped_per_year.value != 0 &&
      this.sqdForm.controls.ext_incidents_per_year.value != '.' && this.sqdForm.controls.pieces_shipped_per_year.value != '.'
      &&  this.sqdForm.controls.ext_incidents_per_year.value != null && this.sqdForm.controls.pieces_shipped_per_year.value != null
      &&       this.sqdForm.controls.ext_incidents_per_year.value != undefined && this.sqdForm.controls.pieces_shipped_per_year.value != undefined) {
      this.sqdForm.controls.external_parts_per_million.setValue(((this.sqdForm.controls.ext_incidents_per_year.value / this.sqdForm.controls.pieces_shipped_per_year.value) * 1000000).toFixed(1));

    } else {
      this.sqdForm.controls.external_parts_per_million.setValue('');
    }
  }

}
