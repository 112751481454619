<div class="col-md-12 container-login">
    <div class="alert-success" *ngIf="successAlert">
        <img style="margin-right: 1em;" src="../../assets/img/checkCircle.png" alt="image not found">
        <span>{{this.successMessage}}</span>
    </div>
    <div class="alert-failure" *ngIf="failureAlert">
        <img style="margin-right: 1em;" src="../../assets/img/errCircle.png" alt="image not found">
        <span>{{this.failureMessage}}</span>
    </div>
    <div>
        <div class="col-md-12 center " id="btn-login">
            <h2 class="lgn"> <strong>LOGIN</strong></h2>

            <form [formGroup]="loginForm">
                <div class="d-flex center header" id="btn-login-conatiner">
                    <div style="width: 33vh;">
                        <label class="label-login">DUNS Number</label>
                    </div>
                    <div>
                        <input *ngIf="!showOtp" bs-tooltip title="Please Enter DUNS Number" type="text" id="duns_no" [maxLength]="9"
                            formControlName="duns_no" [(ngModel)]="duns_no" (keypress)="onNumberValidate($event,9)">
                        <input *ngIf="showOtp" bs-tooltip title="Please Enter DUNS Number" type="text" id="duns_no"
                            formControlName="duns_no" [(ngModel)]="duns_no" (keypress)="onNumberValidate($event,9)"
                            readonly>

                        <div class="btn-tag">
                            <a href="" [routerLink]="['/newuser']" style=" color: #971518;" id="a-tag">New users click
                                here!
                            </a>
                        </div>
                    </div>
                    <div>
                        <img src="./../../assets/img/help.jpg"
                            onclick="window.open('http://www.dnb.com/get-a-duns-number.html', '_blank');"
                            class="psi-img">
                    </div>
                </div>
                <div *ngIf="err_duns ">
                    <label  style="width: 29vh;"></label> <label class="alert alert-danger">{{err_duns}}</label>
                </div>
                <div *ngIf="userErr">
                    <label  style="width: 29vh;"></label> <label class="alert alert-danger">User does not
                        exist</label>
                </div>

                <br>
                <div class="d-flex center header" id="email">
                    <div class="email">
                        <label>Email ID</label>
                    </div>
                    <div>
                        <input *ngIf="!showOtp" bs-tooltip title="Please Enter Email" type="email" id="btn-email"
                            formControlName="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9._-]+\.[A-Za-z]{2,4}$">
                        <input *ngIf="showOtp" bs-tooltip title="Please Enter Email" type="email" id="btn-email"
                            formControlName="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9._-]+\.[A-Za-z]{2,4}$"
                            readonly>
                    </div>
                </div>

                <div *ngIf="err_email">
                    <label class="otp"></label>
                    <label class="alert alert-danger">Please Enter Your Email ID</label>
                </div>
                <div *ngIf="validEmail">
                    <label class="otp"></label>
                    <label class="alert alert-danger">Please Enter Valid Email</label>
                </div>
                <div *ngIf="error_email">
                    <label class="otp"></label>
                    <label class="alert alert-danger"> Please Enter Registered Email</label>
                </div>
                <br>
                <div class="d-flex center header" id="otp">
                    <div class="otp" *ngIf=" showOtp == true">
                        <label>One Time Password</label>
                    </div>
                    <div>
                        <input [maxLength]="6" *ngIf=" showOtp == true" bs-tooltip title="Please Enter One Time Password" type="text"
                            id="passwrd" formControlName="otp" [(ngModel)]="otp"
                            (keypress)="onNumberValidate($event,9)"> <br>

                        <button class="buttonPSL" (click)="onGenerateOTP()" *ngIf="!showOtp">Generate One Time
                            Password</button>
                        <a *ngIf="showOtp" style=" color: #971518;" (click)="onGenerateOTP()"> Resend One Time
                            Password</a>
                        <div *ngIf="err_otp">

                            <label class="alert alert-danger">Please enter your
                                One Time Password</label>
                        </div>
                    </div>
                </div>


                <div *ngIf="error">
                    <label class="alert alert-danger"> Invalid User ID or One Time Password</label>
                </div>
                <div *ngIf="err_wrong_otp ">
                    <label class="otp"></label>
                    <label class="alert alert-danger">Please Provide Correct One Time Password</label>
                </div>
                <br>
                <button *ngIf="showOtp" class="buttonPSLogin " (click)="login()">LOGIN</button>
            </form>
        </div>

    </div>
    <br>
    <p class="center" id="workflow">Supplier Application Workflow:</p>
    <div class=" padding-div">

        <span>Thank you for your interest in becoming a Toyota supplier. Please note that this is for prospective
            suppliers only. Login or create an account to proceed with the application process.
        </span>
        <div id="btn-profile">
            <span> You will be required to submit a Company Profile. Please click <a (click)="downloadFile()"
                    style=" color: #971518;" id="a-tag"> here</a> to see an example.</span>
        </div>
    </div>
    <div class="center">
        <img class="flow-img" src="./../../assets/img/Toyotaprocess.png">
    </div>

    <div id="btn-contact" style="display: none;">
        <p>
            For technical assistance, please contact our centralized help desk at 1-855-349-9898 or e-mail
            IS_Service_Desk&#64;toyota.com </p>
        <p>©2018 Toyota Motor Engineering & Manufacturing North America </p>
        <p>Terms of Use | Privacy Policy </p>
    </div>
</div>

