<br>
<div class="col-md-12">
    <form [formGroup]="sqdForm">
        <div>
            <h2>SAFETY</h2>
            <div class="d-flex">
                <label style="width: 35%;">North American Industry Classification Code (NAICS)</label>
                <input bs-tooltip title="Please Enter NAICS Number" type="text" appDecimaNumber   formControlName="naics"
                    id="naics">
            </div>
            <br>
            <div class="d-flex">
                <label style="width: 35%;">Number of Fatalities (last 3 years)</label>
                <input bs-tooltip title="Please Enter Fatalities Number" type="text" appDecimaNumber   id="fatalities_number"
                    formControlName="fatalities_number">
            </div>
            <br>
            <div class="d-flex">
                <label style="width: 35%;">Total Incident Rate <br>
                    {{total_incident}}
                </label>
                <input bs-tooltip title="Please Enter Number of Incidents in the Last 12 months" style="height: 30px;"
                    type="text" appDecimaNumber   id="incidents_per_year" formControlName="incidents_per_year"
                    (change)="totalIncidentRate()"> &nbsp; / &nbsp;
                <input bs-tooltip title="Please Enter Employee Hours spent in the Last 12 months" style="height: 30px;"
                    type="text" appDecimaNumber   id="employee_hrs_per_year" formControlName="employee_hrs_per_year"
                    (change)="totalIncidentRate()">
                &nbsp; = &nbsp;
                <input bs-tooltip title="Total Incident Rate" style="height: 30px;" type="text"   readonly 
                    formControlName="total_incident_rate">
            </div>

            <div class="d-flex">
                <label style="width: 35%;">Industry Average Total Incident Rate </label>
                <input bs-tooltip title="Please Enter Industry Average Incident Rate" type="text" appDecimaNumber  
                    id=" industry_avg_incident_rate" formControlName="industry_avg_incident_rate">
            </div>
            <br>
            <div style="margin-left: 20%;">
                <label> <input id="trackmetricsforsafety" size="25px" id="track_safety_metrics"
                        formControlName="track_safety_metrics" name="trackmetricsforsafety"
                        onclick="toggleSafeChk(this);" type="checkbox"></label>
                &nbsp;<label for="trackmetricsforsafety"> I do not track metrics for Safety</label>
            </div>
        </div>

        <div>
            <h2>QUALITY</h2>
            <div class="d-flex">

                <label style="width: 35%;">Internal Parts per Million <br>
                    {{total_internal}}
                </label>
                <input bs-tooltip title="Please Enter Number of Defects in the Last 12 months" style="height: 30px;"
                    type="text" appDecimaNumber   formControlName="defects_per_year" id="defects_per_year"
                    (change)="internal_parts_per_million()"> &nbsp; / &nbsp;
                <input bs-tooltip title="Please Enter Number of Pieces Made in the Last 12 months" style="height: 30px;"
                    type="text" appDecimaNumber   formControlName="pieces_made_per_year" id="pieces_made_per_year"
                    (change)="internal_parts_per_million()">
                &nbsp; = &nbsp;
                <input bs-tooltip title="Internal Parts Per Million" style="height: 30px;" type="text"   readonly
                     formControlName="internal_parts_per_million" id="internal_parts_per_million">&nbsp;PPM
            </div>


            <div class="d-flex">
                <label style="width: 35%;">External Parts per Million <br>
                    {{total_external}}

                </label>
                <input bs-tooltip title="Please Enter Number of Incidents in the Last 12 months" style="height: 30px;"
                    type="text" appDecimaNumber   formControlName="ext_incidents_per_year" id="ext_incidents_per_year"
                    (change)="external_parts_per_million()"> &nbsp; / &nbsp;
                <input bs-tooltip title="Please Enter Number of Pieces Shipped in the Last 12 months"
                    style="height: 30px;" type="text" appDecimaNumber   formControlName="pieces_shipped_per_year"
                    id="pieces_shipped_per_year" (change)="external_parts_per_million()">
                &nbsp; = &nbsp;
                <input bs-tooltip title="External Parts Per Million" style="height: 30px;" type="text"   readonly
                     formControlName="external_parts_per_million" id="external_parts_per_million">&nbsp;PPM
            </div>
            <br>
            <div style="margin-left: 20%;">
                <label><input id="track_quality_metrics" name="track_quality_metrics" onclick="toggleQualChk(this);"
                        formControlName="track_quality_metrics" type="checkbox"
                        title="Please Check if Quality Metrics are Tracked"></label>
                &nbsp; <label>I do not track metrics for Quality</label>

            </div>
        </div>

        <div>
            <h2>
                DELIVERY
            </h2>
            <div class="d-flex">
                <label style="width: 35%;">On Time Delivery Percentage</label>
                <input bs-tooltip title="Please Enter On time Delivery Percentage" type="text" appDecimaNumber  
                    id="ontime_delivery_percentage" formControlName="ontime_delivery_percentage"> &nbsp; %
            </div>
            <br>
            <div style="margin-left: 20%;">
                <label><input id="track_delivery_metrics" name="track_delivery_metrics"
                        formControlName="track_delivery_metrics" onclick="toggleDelChk(this);" type="checkbox"></label>
                &nbsp; <label>I do not track metrics for Delivery</label>

            </div>
        </div>
    </form>

</div>