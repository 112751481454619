
export const environment = {
  envName: 'dev',
  production: false,
  hostname: 'devnew.psi.toyotasupplier.com',
  apiBaseUrl: 'https://devnew.psi.toyotasupplier.com',
  logger: {
    level: 'DEBUG'
  },
  xApiKey: '',

  apiUrl: {
    //lookupUrl: 'https://jj28jmj5vf.execute-api.us-west-2.amazonaws.com/dev',
    // lookupUrl: 'https://ssd5ai1lk7.execute-api.us-west-2.amazonaws.com/dev/serviceLookup',
    lookupUrl: 'https://api.dev.psi.toyotasupplier.com/serviceLookup',
 
    //registerURL:'https://jyit5gcw4d.execute-api.us-west-2.amazonaws.com/dev',
    registerURL:'https://api.dev.psi.toyotasupplier.com/serviceRegister',
    // registerURL:'https://ssd5ai1lk7.execute-api.us-west-2.amazonaws.com/dev/serviceRegistor',

  }

};
