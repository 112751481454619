import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  myimage: string = './assets/images/Footer-logo.svg';
  currentYear: number | undefined;
  mobile_screen: boolean = false;
  public innerWidth: any;
  path: string;

  constructor(private router: Router) {
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          this.path = this.router.url;
        }
      }
    );
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

  }


}
