
export const environment = {
  envName: 'qa',
  production: false,
  hostname: 'qanew.psi.toyotasupplier.com',
  apiBaseUrl: 'https://qanew.psi.toyotasupplier.com',
  logger: {
    level: 'DEBUG'
  },
  xApiKey: '', 
  apiUrl: {
    //lookupUrl: 'https://g1o4aulyha.execute-api.us-west-2.amazonaws.com/qa/serviceLookup',
    lookupUrl: 'https://api.qa.psi.toyotasupplier.com/serviceLookup',
 
    //registerURL:' https://g1o4aulyha.execute-api.us-west-2.amazonaws.com/qa/serviceRegister',
    registerURL:'https://api.qa.psi.toyotasupplier.com/serviceRegister',  
  } 
};
