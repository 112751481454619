import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { RegistrationService } from '../services/registration.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { data } from 'jquery';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
  registrationform: any;
  duns_no: number;
  email: string;
  duns_no_error: string;
  email_error: string;
  validation_error: string;
  otp: any;
  otpEnable: boolean = false; otpErr: boolean;
  successMessage: string = '';
  failureAlert: boolean = false;
  successAlert: boolean = false;
  failureMessage: string;
  otpVerifyErr: boolean;
  constructor(private fb: UntypedFormBuilder, private http: HttpClient, private ngxSpinner: NgxSpinnerService, private router: Router, private registrationService: RegistrationService, private lookupService: LookupService) { }

  ngOnInit() {
    this.registrationform = this.fb.group({
      duns_no: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
      email: ['', [Validators.required, Validators.email]],
      otp: ['', []]
    });
  }

  onGenerateOTP() {
    this.otpErr = false;
    this.registrationform.markAllAsTouched();
    this.successMessage = '';
    this.successAlert = false;
    this.failureAlert = false;
    this.validation_error = '';
    this.email_error = '';
    if (this.registrationform.valid && this.duns_no.toString().length == 9) {
      this.ngxSpinner.show();
      this.registrationService.supplierRegister(this.registrationform.controls.email.value, parseInt(this.registrationform.controls.duns_no.value)).subscribe(data => {
        this.ngxSpinner.hide();
        
        debugger;
        if (data.body == 'User already exist' || data.body == 'User already registered') {
          this.validation_error = 'User already exist. Please try to login'
        }
        else if (data.body == 'Email already exist') {
          this.validation_error = 'Email already exist. Please try with different email ID'
        }
        else if (data.body == 'Duns Number already exist') {
          this.validation_error = 'Duns number already exist. Please try with different duns number'
        }
        else if (data.body == "otp is wrong") {
          this.validation_error = 'Please provide Correct One Time Password'
        }
        else if (data.body == 'Mail sent successfully') {
          this.otpEnable = true;
          this.otpErr = false;
          this.successAlert = true;
          this.successMessage = 'Email send successfully to ' + this.registrationform.controls.email.value;

        }
        else if (data.body == 'Failed to send mail') {
          this.failureAlert = true;
          this.failureMessage = 'Failed to send mail. Please try after sometime.';
        }
        else {
          this.validation_error = "Failed to send mail. Please try after sometime.";

        }
        setTimeout(() => {
          this.failureAlert = false;
          this.successMessage = '';
          this.failureMessage = '';
          this.successAlert = false;
        }, 5000);
      }, (error) => {
        console.log(error);
        this.ngxSpinner.hide();
        this.failureAlert = true;
        this.failureMessage = "Something went wrong. Please try after sometime.";
        setTimeout(() => {
          this.failureAlert = false;
          this.successMessage = '';
          this.failureMessage = '';
          this.successAlert = false;
        }, 3000);
      })

    }


    else {
      if (!this.duns_no) {
        this.duns_no_error = "Please Enter Your DUNS Number.";
      }
      else if (this.duns_no.toString().length != 9) {
        this.duns_no_error = 'DUNS Number should be 9 digits.'
      }

      else {
        this.duns_no_error = '';
      }
      if (!this.email) {
        this.email_error = "Please Enter Your Email ID.";
      }
      else if (this.registrationform.controls['email'].errors) {
        this.email_error = "Please Enter Valid Email ID.";
      }
      else {
        this.email_error = '';
      }
    }
  }
  onNumberValidate(event, len) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) || event.key === "-" || event.target.value.length === len ||
      event.key.toUpperCase() === "E" || event.key === '.') {
      return false;
    }
    return true;

  }
  onVerifyOTP() {
    //  this.otpEnable = true;
    this.otpVerifyErr = false;
    this.otpErr = false;
    if (!this.registrationform.controls.otp.value) {
      this.otpErr = true;
    }
    debugger;
    if (this.registrationform.valid && this.registrationform.controls.otp.value != undefined && this.registrationform.controls.otp.value != null && this.registrationform.controls.otp.value != '' && this.duns_no.toString().length == 9) {
      this.ngxSpinner.show();
      this.lookupService.verifyOTPRegister(this.registrationform.controls.otp.value,
        parseInt(this.registrationform.controls.duns_no.value)).subscribe(data => {
      
          let response: any = data;


          if (response.body == 'User already exist' || response.body == 'User already Registered.') {
            this.validation_error = 'User already exist. Please try to login'
          }
          else if (response.body == "otp is wrong") {
            this.otpVerifyErr = true;

          }
          else if (response.body.length > 0) {
            this.successMessage = 'Registration successfully';
            this.router.navigate(['/login']);
          }
          else {
            this.validation_error = "Duns number or email id already exists";

          }
          setTimeout(() => {
            this.successMessage = '';
            this.successAlert = false;

          }, 2000);
          this.ngxSpinner.hide();

        }, (error) => {
          console.log(error);
          this.failureAlert = true;
          this.ngxSpinner.hide();
          this.failureMessage = "Something went wrong. Please try after sometime.";
          setTimeout(() => {
            this.failureAlert = false;
            this.successMessage = '';
            this.failureMessage = '';
            this.successAlert = false;
          }, 3000);
        })
    }
    else {
      if (this.registrationform.controls.otp.value == '') {
        this.otpErr = true;
      }
      if (!this.duns_no) {
        this.duns_no_error = "Please Enter Your DUNS Number.";
      }
      else if (this.duns_no.toString().length != 9) {
        this.duns_no_error = 'DUNS Number should be 9 digits.'
      }

      else {
        this.duns_no_error = '';
      }
      if (!this.email) {
        this.email_error = "Please Enter Your Email ID.";
      }
      else if (this.registrationform.controls['email'].errors) {
        this.email_error = "Please Enter Valid Email ID.";
      }
      else {
        this.email_error = '';
      }
    }
  }
}

