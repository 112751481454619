import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LookupService } from '../services/lookup.service';
import { RegistrationService } from '../services/registration.service';

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss']
})
export class SubmitComponent implements OnInit {

  duns_number = sessionStorage.getItem('duns_number');
  email = sessionStorage.getItem('email');

  constructor(private registrationService: RegistrationService,private route: Router, private ngxSpinner: NgxSpinnerService, private router: Router) {

  }

  ngOnInit(): void {
    if (this.duns_number == null || this.duns_number == '' || this.duns_number == undefined) {
      this.route.navigate(['/login']);
    }else{
    this.sendMail();
    }
  }

  sendMail() {
    this.ngxSpinner.show();
    this.registrationService.submitMail(this.email, this.duns_number).subscribe(data => {   
      sessionStorage.clear();
      localStorage.clear();   
      this.ngxSpinner.hide();
      setTimeout(() => {
      
        this.router.navigate(['/login']);
      }, 30000);
    });
  }



}