import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegistrationService } from '../services/registration.service';
import { isNumber, NgbDateCustomParserFormatter, toInteger } from '../services/dateFormat';

@Component({
  selector: 'app-regulatory',
  templateUrl: './regulatory.component.html',
  styleUrls: ['./regulatory.component.scss']
})
export class RegulatoryComponent implements OnInit {

  nmsd_value = '';
  nglcchk_value = '';
  nvbdcchk_value = '';
  usblnchk_value = '';
  wbencchk_value = '';
  regulatoryForm: UntypedFormGroup;
  duns_number = sessionStorage.getItem('duns_number');
  email = sessionStorage.getItem('email');
  dateFormate = new NgbDateCustomParserFormatter;
  submitValue: boolean;
  successMessage: string = '';
  failureAlert: boolean = false;
  successAlert: boolean = false;
  failureMessage: string;

  constructor(private formBuilder: UntypedFormBuilder, private registrationService: RegistrationService, private ngxSpinner: NgxSpinnerService) {
    this.regulatoryForm = this.formBuilder.group({});
    this.regulatoryForm = new UntypedFormGroup({
      minority_owned: new UntypedFormControl('', [Validators.required]),
      nmsdc_certification_num: new UntypedFormControl('', []),
      nmsdc_cert_expiry_date: new UntypedFormControl('', []),
      women_owned: new UntypedFormControl('', [Validators.required]),
      wbenc_certification_num: new UntypedFormControl('', []),
      wbenc_cert_expiry_date: new UntypedFormControl('', []),
      nglcc_certification_num: new UntypedFormControl('', []),
      nglcc_cert_expiry_date: new UntypedFormControl('', []),
      veteran_owned: new UntypedFormControl('', [Validators.required]),
      nvbdc_certification_num: new UntypedFormControl('', []),
      nvbdc_cert_expiry_date: new UntypedFormControl('', []),
      disability_owned: new UntypedFormControl('', [Validators.required]),
      usbln_certification_num: new UntypedFormControl('', []),
      usbln_cert_expiry_date: new UntypedFormControl('', []),
      lgbt_owned: new UntypedFormControl('', [Validators.required]),
      wbec: new UntypedFormControl('', [Validators.required]),
      wbec_certification_num: new UntypedFormControl('', []),
      wbec_cert_expiry_date: new UntypedFormControl('', []),
      aboriginal_owned: new UntypedFormControl('', [Validators.required]),
      camsc_certification_num: new UntypedFormControl('', []),
      camsc_cert_expiry_date: new UntypedFormControl('', []),
    });

  }

  ngOnInit(): void {

    this.registrationService.getRegulatoryInfo(this.duns_number).subscribe(data => {
      if (data.statusCode == 200) {
        this.regulatoryForm.controls.minority_owned.setValue(data.body[0].minority_owned);

        this.regulatoryForm.controls.nmsdc_certification_num.setValue(data.body[0].nmsdc_certification_num);
        if (data.body[0].nmsdc_cert_expiry_dt)
          this.regulatoryForm.controls.nmsdc_cert_expiry_date.setValue(this.dateFormate.dateFormate(data.body[0].nmsdc_cert_expiry_dt));


        this.regulatoryForm.controls.women_owned.setValue(data.body[0].women_owned);
        this.regulatoryForm.controls.wbenc_certification_num.setValue(data.body[0].wbenc_certification_num);
        if (data.body[0].wbenc_cert_expiry_dt)
          this.regulatoryForm.controls.wbenc_cert_expiry_date.setValue(this.dateFormate.dateFormate(data.body[0].wbenc_cert_expiry_dt));

        this.regulatoryForm.controls.lgbt_owned.setValue(data.body[0].lgbt_owned);
        this.regulatoryForm.controls.nglcc_certification_num.setValue(data.body[0].nglcc_certification_num);
        if (data.body[0].nglcc_cert_expiry_dt)
          this.regulatoryForm.controls.nglcc_cert_expiry_date.setValue(this.dateFormate.dateFormate(data.body[0].nglcc_cert_expiry_dt));

        this.regulatoryForm.controls.veteran_owned.setValue(data.body[0].veteran_owned);
        this.regulatoryForm.controls.nvbdc_certification_num.setValue(data.body[0].nvbdc_certification_num)
        if (data.body[0].nvbdc_cert_expiry_dt)
          this.regulatoryForm.controls.nvbdc_cert_expiry_date.setValue(this.dateFormate.dateFormate(data.body[0].nvbdc_cert_expiry_dt));


        this.regulatoryForm.controls.disability_owned.setValue(data.body[0].disability_owned);
        this.regulatoryForm.controls.usbln_certification_num.setValue(data.body[0].usbln_certification_num);
        if (data.body[0].usbln_cert_expiry_dt)
          this.regulatoryForm.controls.usbln_cert_expiry_date.setValue(this.dateFormate.dateFormate(data.body[0].usbln_cert_expiry_dt));

      }

      this.regulatoryForm.controls.aboriginal_owned.setValue(data.body[0].aboriginal_owned);
      this.regulatoryForm.controls.camsc_certification_num.setValue(data.body[0].camsc_certification_num);
      if (data.body[0].camsc_cert_expiry_dt)
        this.regulatoryForm.controls.camsc_cert_expiry_date.setValue(this.dateFormate.dateFormate(data.body[0].camsc_cert_expiry_dt));

      this.regulatoryForm.controls.wbec.setValue(data.body[0].women_owned_ca);
      this.regulatoryForm.controls.wbec_certification_num.setValue(data.body[0].wbec_certification_num);
      if (data.body[0].wbec_cert_expiry_dt)
        this.regulatoryForm.controls.wbec_cert_expiry_date.setValue(this.dateFormate.dateFormate(data.body[0].wbec_cert_expiry_dt));
    });
  }
  radioValueChange(id, value) {
    debugger;
    if (id == 'minority_owned') {
      if (value == 'Y') {
        this.regulatoryForm.get('nmsdc_certification_num').setValidators([Validators.required]);
        this.regulatoryForm.get('nmsdc_cert_expiry_date').setValidators([Validators.required]);

      } else if (value == 'N') {
        this.regulatoryForm.get('nmsdc_certification_num').setValidators([]);
        this.regulatoryForm.get('nmsdc_cert_expiry_date').setValidators([]);
      }
      this.regulatoryForm.get('nmsdc_certification_num').updateValueAndValidity();
      this.regulatoryForm.get('nmsdc_cert_expiry_date').updateValueAndValidity();
    }
    else if (id == 'lgbt_owned') {
      if (value == 'Y') {
        this.regulatoryForm.controls.nglcc_certification_num.setValidators([Validators.required]);

        this.regulatoryForm.controls.nglcc_cert_expiry_date.setValidators([Validators.required]);
      } else if (value == 'N') {
        this.regulatoryForm.controls.nglcc_certification_num.setValidators([]);
        this.regulatoryForm.controls.nglcc_cert_expiry_date.setValidators([]);
      }
      this.regulatoryForm.get('nglcc_certification_num').updateValueAndValidity();
      this.regulatoryForm.get('nglcc_cert_expiry_date').updateValueAndValidity();
    }
    else if (id == 'veteran_owned') {
      if (value == 'Y') {
        this.regulatoryForm.controls.nvbdc_certification_num.setValidators([Validators.required]);
        this.regulatoryForm.controls.nvbdc_cert_expiry_date.setValidators([Validators.required]);

      } else if (value == 'N') {
        this.regulatoryForm.controls.nvbdc_certification_num.setValidators([]);
        this.regulatoryForm.controls.nvbdc_cert_expiry_date.setValidators([]);
      }

      this.regulatoryForm.get('nvbdc_certification_num').updateValueAndValidity();
      this.regulatoryForm.get('nvbdc_cert_expiry_date').updateValueAndValidity();
    }
    else if (id == 'disability_owned') {
      if (value == 'Y') {
        this.regulatoryForm.controls.usbln_certification_num.setValidators([Validators.required]);
        this.regulatoryForm.controls.usbln_cert_expiry_date.setValidators([Validators.required]);

      } else if (value == 'N') {
        this.regulatoryForm.controls.usbln_certification_num.setValidators([]);
        this.regulatoryForm.controls.usbln_cert_expiry_date.setValidators([]);
      }

      this.regulatoryForm.get('usbln_certification_num').updateValueAndValidity();
      this.regulatoryForm.get('usbln_cert_expiry_date').updateValueAndValidity();
    }
    else if (id == 'women_owned') {
      if (value == 'Y') {
        this.regulatoryForm.controls.wbenc_certification_num.setValidators([Validators.required]);
        this.regulatoryForm.controls.wbenc_cert_expiry_date.setValidators([Validators.required]);
      } else if (value == 'N') {
        this.regulatoryForm.controls.wbenc_certification_num.setValidators([]);
        this.regulatoryForm.controls.wbenc_cert_expiry_date.setValidators([]);
      }

      this.regulatoryForm.get('wbenc_certification_num').updateValueAndValidity();
      this.regulatoryForm.get('wbenc_cert_expiry_date').updateValueAndValidity();
    }
    else if (id == 'wbec') {
      if (value == 'Y') {
        this.regulatoryForm.controls.wbec_certification_num.setValidators([Validators.required]);
        this.regulatoryForm.controls.wbec_cert_expiry_date.setValidators([Validators.required]);
      } else if (value == 'N') {
        this.regulatoryForm.controls.wbec_certification_num.setValidators([]);
        this.regulatoryForm.controls.wbec_cert_expiry_date.setValidators([]);
      }

      this.regulatoryForm.get('wbec_certification_num').updateValueAndValidity();
      this.regulatoryForm.get('wbec_cert_expiry_date').updateValueAndValidity();
    }
    else if (id == "aboriginal_owned") {
      if (value == 'Y') {
        this.regulatoryForm.controls.camsc_certification_num.setValidators([Validators.required]);
        this.regulatoryForm.controls.camsc_cert_expiry_date.setValidators([Validators.required]);
      } else if (value == 'N') {
        this.regulatoryForm.controls.camsc_certification_num.setValidators([]);
        this.regulatoryForm.controls.camsc_cert_expiry_date.setValidators([]);
      }
      this.regulatoryForm.get('camsc_certification_num').updateValueAndValidity();
      this.regulatoryForm.get('camsc_cert_expiry_date').updateValueAndValidity();
    }
  }

  onSave() {
    this.submitValue = false;
    let data: any = {};
    this.regulatoryForm.markAllAsTouched();
    if (this.regulatoryForm.valid) {
      this.ngxSpinner.show();
      data.duns_number = this.duns_number;
      data.minority_owned = this.regulatoryForm.controls.minority_owned.value;
      if (this.regulatoryForm.controls.minority_owned.value == 'Y') {
        data.nmsdc_certification_num = this.regulatoryForm.controls.nmsdc_certification_num.value;
        data.nmsdc_cert_expiry_date = this.regulatoryForm.controls.nmsdc_cert_expiry_date.value;
      }

      data.women_owned = this.regulatoryForm.controls.women_owned.value;
      if (this.regulatoryForm.controls.women_owned.value == 'Y') {
        data.wbenc_certification_num = this.regulatoryForm.controls.wbenc_certification_num.value;
        data.wbenc_cert_expiry_date = this.regulatoryForm.controls.wbenc_cert_expiry_date.value;
      }


      data.lgbt_owned = this.regulatoryForm.controls.lgbt_owned.value;
      if (this.regulatoryForm.controls.lgbt_owned.value == 'Y') {
        data.nglcc_certification_num = this.regulatoryForm.controls.nglcc_certification_num.value;
        data.nglcc_cert_expiry_date = this.regulatoryForm.controls.nglcc_cert_expiry_date.value;
      }

      data.veteran_owned = this.regulatoryForm.controls.veteran_owned.value;
      if (this.regulatoryForm.controls.veteran_owned.value == 'Y') {
        data.nvbdc_certification_num = this.regulatoryForm.controls.nvbdc_certification_num.value;
        data.nvbdc_cert_expiry_date = this.regulatoryForm.controls.nvbdc_cert_expiry_date.value;

      }

      data.disability_owned = this.regulatoryForm.controls.disability_owned.value;
      if (this.regulatoryForm.controls.disability_owned.value == 'Y') {
        data.usbln_certification_num = this.regulatoryForm.controls.usbln_certification_num.value;
        data.usbln_cert_expiry_date = this.regulatoryForm.controls.usbln_cert_expiry_date.value;
      }

      data.women_owned_ca = this.regulatoryForm.controls.wbec.value;
      if (this.regulatoryForm.controls.wbec.value == 'Y') {
        data.wbec_certification_num = this.regulatoryForm.controls.wbec_certification_num.value;
        data.wbec_cert_expiry_date = this.regulatoryForm.controls.wbec_cert_expiry_date.value;
      }

      data.aboriginal_owned = this.regulatoryForm.controls.aboriginal_owned.value;
      if (this.regulatoryForm.controls.aboriginal_owned.value == 'Y') {
        data.camsc_certification_num = this.regulatoryForm.controls.camsc_certification_num.value;
        data.camsc_cert_expiry_date = this.regulatoryForm.controls.camsc_cert_expiry_date.value;
      }
      data.created_by = this.email;
      this.registrationService.insertRegulator(data).subscribe(res => {

        if (res["statusCode"] === 201 || res["statusCode"] === 200) {
          this.submitValue = true;
          this.ngOnInit();
        }


      }, (error) => {
        console.log(error);
        this.ngxSpinner.hide();
        this.submitValue = false;
        this.failureAlert = true;
        this.failureMessage = "Something went wrong. Please try after sometime.";
        setTimeout(() => {
          this.failureAlert = false;
          this.failureMessage = '';
        }, 3000);
      })
    }
  }

}
