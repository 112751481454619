<br>
<div class="col-md-12">
    <div>
        <h2> Workplace Diversity
            Questionnaire </h2>
    </div>
    <br>
    <form [formGroup]="regulatoryForm" style="font-size:.9vw">
        <div class="d-flex" style="padding-top: 10px;">
            <label style="width: 45%;" class="required"> Are you a
                certified Minority Owned Business (certified by the NMSDC)?
            </label>
            <input name="minority_owned" id="minority_owned" type="radio"
                (click)="radioValueChange('minority_owned','Y')" value="Y"
                formControlName="minority_owned"><span>Yes</span>
            &nbsp;
            <input name="minority_owned" id="minority_ownedNo" type="radio"
                (click)="radioValueChange('minority_owned','N')" value="N"
                formControlName="minority_owned"><span>No</span>
        </div>
        <div style="width: 55%;display: flex;justify-content: flex-end;"
            *ngIf="regulatoryForm.controls['minority_owned'].invalid && regulatoryForm.controls['minority_owned'].touched">
            <div  class="alert alert-danger "
                *ngIf="regulatoryForm.controls['minority_owned'].errors.required">
                Please select Yes or No
            </div>
        </div>
        <div *ngIf="regulatoryForm.controls.minority_owned.value == 'Y'">
            <div class="d-flex" id="dvMintext">
                <label style="width: 20%;"> NMSDC Certification Number</label>

                <div>
                    <div style="margin-bottom: 2%;">
                        <input id="nmsdc_certification_num" type="text" bs-tooltip
                            title="Please Enter NMSDC Certification Number" formControlName="nmsdc_certification_num"
                            title="Please Enter NMSDC Certification Number">
                    </div>

                    <div
                        *ngIf="regulatoryForm.controls['nmsdc_certification_num'].invalid && regulatoryForm.controls['nmsdc_certification_num'].touched">

                        <div class="alert alert-danger"
                            *ngIf="regulatoryForm.controls['nmsdc_certification_num'].errors.required">
                            Enter NMSDC Certification Number
                        </div>
                    </div>
                </div>

            </div>
            <div class="d-flex" id="dvMinDatetext">
                <label style="width: 20%;"> NMSDC Expiration Date </label>
                <div>
                    <div style="margin-bottom: 2%;">
                        <input id="nmsdc_cert_expiry_date" name="nmsdc_cert_expiry_date" type="date" bs-tooltip
                            formControlName="nmsdc_cert_expiry_date" title="Please Enter NMSDC Cert Expiration Date">
                    </div>

                    <div
                        *ngIf="regulatoryForm.controls['nmsdc_cert_expiry_date'].invalid && regulatoryForm.controls['nmsdc_cert_expiry_date'].touched">
                        <div class="alert alert-danger "
                            *ngIf="regulatoryForm.controls['nmsdc_cert_expiry_date'].errors.required">
                            Enter Valid Expiration Date
                        </div>
                    </div>
                </div>
            </div>
            <br>
        </div>

        <div class="d-flex">
            <label style="width: 45%;" class="required">
                Are you a certified LGBT Owned
                Business (certified by the NGLCC)? </label>
            <input name="lgbt_owned" id="lgbt_owned" formControlName="lgbt_owned" type="radio"
                (click)="radioValueChange('lgbt_owned','Y')" value="Y"><span>Yes</span> &nbsp;
            <input name="lgbt_owned" id="lgbt_ownedN" formControlName="lgbt_owned" type="radio"
                (click)="radioValueChange('lgbt_owned','N')" value="N"><span>No</span>
        </div>
        <div style="width: 55%;display: flex;justify-content: flex-end;"
            *ngIf="regulatoryForm.controls['lgbt_owned'].invalid && regulatoryForm.controls['lgbt_owned'].touched">
            <div class="alert alert-danger " *ngIf="regulatoryForm.controls['lgbt_owned'].errors.required">
                Please select Yes or No
            </div>
        </div>
        <div *ngIf=" regulatoryForm.controls.lgbt_owned.value =='Y' ">
            <div class="d-flex" id="dvLGtext">
                <label style="width: 20%;"> NGLCC Certification Number </label>
                <div>
                    <div style="margin-bottom: 2%;">
                        <input id="nglcc_certification_num" name="nglcc_certification_num" type="text" bs-tooltip
                            title="Please Enter NGLCC Certification Number" formControlName="nglcc_certification_num"
                            title="Please Enter NGLCC Certification Number">
                    </div>
                    <div
                        *ngIf="regulatoryForm.controls['nglcc_certification_num'].invalid && regulatoryForm.controls['nglcc_certification_num'].touched">
                        <div class="alert alert-danger "
                            *ngIf="regulatoryForm.controls['nglcc_certification_num'].errors.required">
                            Enter NGLCC Certification Number
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex" id="dvLGDatetext">
                <label style="width: 20%;"> NGLCC Expiration Date </label>
                <div>
                    <div style="margin-bottom: 2%;">
                        <input id="nglcc_cert_expiry_date" name="nglcc_cert_expiry_date" type="date" bs-tooltip
                            formControlName="nglcc_cert_expiry_date" title="Please Enter NGLCC Cert Expiration Date">
                    </div>

                    <div
                        *ngIf="regulatoryForm.controls['nglcc_cert_expiry_date'].invalid && regulatoryForm.controls['nglcc_cert_expiry_date'].touched">
                        <div class="alert alert-danger "
                            *ngIf="regulatoryForm.controls['nglcc_cert_expiry_date'].errors.required">
                            Enter Valid Expiration Date
                        </div>
                    </div>
                </div>
            </div>
            <br>
        </div>
        <div class="d-flex">
            <label style="width: 45%;">
                Are you a certified Veteran Owned
                Business (certified by the NVBDC)?<span class="required"></span> </label>
            <input name="veteran_owned" id="veteran_owned" formControlName="veteran_owned" type="radio"
                (click)="radioValueChange('veteran_owned','Y')" value="Y"><span>Yes</span>
            &nbsp;
            <input name="veteran_owned" id="veteran_ownedN" formControlName="veteran_owned" type="radio"
                (click)="radioValueChange('veteran_owned','N')" value="N"><span>No</span>
           
            </div>
            <div style="width: 55%;display: flex;justify-content: flex-end;"
                *ngIf="regulatoryForm.controls['veteran_owned'].invalid && regulatoryForm.controls['veteran_owned'].touched">
                <div  class="alert alert-danger "
                    *ngIf="regulatoryForm.controls['veteran_owned'].errors.required">
                    Please select Yes or No
                </div>
                <br>
            </div>

            <div *ngIf="regulatoryForm.controls.veteran_owned.value == 'Y'">
                <div class="d-flex" id="dvVettext">
                    <label style="width: 20%;"> NVBDC Certification Number </label>
                    <div>
                        <div style="margin-bottom: 2%;">
                            <input id="nvbdc_certification_num" name="nvbdc_certification_num" type="text" bs-tooltip
                                title="Please Enter NVBDC Certification Number"
                                formControlName="nvbdc_certification_num"
                                title="Please Enter NVBDC Certification Number">
                        </div>

                        <div
                            *ngIf="regulatoryForm.controls['nvbdc_certification_num'].invalid && regulatoryForm.controls['nvbdc_certification_num'].touched">
                            <div class="alert alert-danger "
                                *ngIf="regulatoryForm.controls['nvbdc_certification_num'].errors.required">
                                Enter NVBDC Certification Number
                            </div>
                        </div>
                    </div>
                </div>


                <div class="d-flex" id="dvVetDatetext">
                    <label style="width: 20%;"> NVBDC Expiration Date </label>
                    <div>
                        <div style="margin-bottom: 2%;">
                            <input id="nvbdc_cert_expiry_date" name="nvbdc_cert_expiry_date" type="date" bs-tooltip
                                formControlName="nvbdc_cert_expiry_date"
                                title="Please Enter NVBDC Cert Expiration Date">
                        </div>
                        <div
                            *ngIf="regulatoryForm.controls['nvbdc_cert_expiry_date'].invalid && regulatoryForm.controls['nvbdc_cert_expiry_date'].touched">
                            <div class="alert alert-danger "
                                *ngIf="regulatoryForm.controls['nvbdc_cert_expiry_date'].errors.required">
                                Enter Valid Expiration Date
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>
            <div class="d-flex">
                <label style="width: 45%;" class="required"> Are you a certified Disability Owned
                    Business (certified by the Disability:IN)?</label>

                <input name="disability_owned" id="disability_owned" type="radio"
                    (click)="radioValueChange('disability_owned','Y')" value="Y"
                    formControlName="disability_owned"><span>Yes</span>
                &nbsp;
                <input name="disability_owned" formControlName="disability_owned" id="disability_ownedN" type="radio"
                    (click)="radioValueChange('disability_owned','N')" value="N"><span>No</span>
            </div>
            <div style="width: 55%;display: flex;justify-content: flex-end;"
                *ngIf="regulatoryForm.controls['disability_owned'].invalid && regulatoryForm.controls['disability_owned'].touched">
                <div  class="alert alert-danger "
                    *ngIf="regulatoryForm.controls['disability_owned'].errors.required">
                    Please select Yes or No
                </div>
            </div>
            <div *ngIf="regulatoryForm.controls.disability_owned.value == 'Y' ">
                <div class="d-flex" id="dvDistext">
                    <label style="width: 20%;"> Disability:IN Certification Number </label>
                    <div>
                        <div style="margin-bottom: 2%;">
                            <input id="usbln_certification_num" name="usbln_certification_num" type="text" bs-tooltip
                                title="Please Enter Disability:IN Certification Number"
                                formControlName="usbln_certification_num"
                                title="Please Enter Disability:IN Certification Number">
                        </div>

                        <div
                            *ngIf="regulatoryForm.controls['usbln_certification_num'].invalid && regulatoryForm.controls['usbln_certification_num'].touched">
                            <div class="alert alert-danger "
                                *ngIf="regulatoryForm.controls['usbln_certification_num'].errors.required">
                                Enter Disability Certification Number
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex" id="dvDisDatetext">
                    <label style="width: 20%;"> Disability:IN Expiration Date </label>

                    <div>
                        <div style="margin-bottom: 2%;">
                            <input id="usbln_cert_expiry_date" name="usbln_cert_expiry_date" type="date" bs-tooltip
                                title="Please Enter Disability:IN Cert Expiration Date"
                                formControlName="usbln_cert_expiry_date">
                        </div>

                        <div
                            *ngIf="regulatoryForm.controls['usbln_cert_expiry_date'].invalid && regulatoryForm.controls['usbln_cert_expiry_date'].touched">
                            <div class="alert alert-danger "
                                *ngIf="regulatoryForm.controls['usbln_cert_expiry_date'].errors.required">
                                Enter Valid Expiration Date
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>
            <div class="d-flex">
                <label style="width: 45%;"> Are you a certified Woman Owned
                    Business (certified by the WBENC)?<span class="required">&nbsp;&nbsp;</span></label>
                <input name="women_owned" id="women_owned" type="radio" (click)="radioValueChange('women_owned','Y')"
                    value="Y" formControlName="women_owned"><span>Yes</span>
                &nbsp;
                <input name="women_owned" id="women_ownedN" type="radio" (click)="radioValueChange('women_owned','N')"
                    value="N" formControlName="women_owned"><span>No</span>
            </div>
            <div style="width: 55%;display: flex;justify-content: flex-end;"
                *ngIf="regulatoryForm.controls['women_owned'].invalid && regulatoryForm.controls['women_owned'].touched">
                <div class="alert alert-danger " *ngIf="regulatoryForm.controls['women_owned'].errors.required">
                    Please select Yes or No
                </div>
            </div>
            <div *ngIf=" regulatoryForm.controls.women_owned.value == 'Y'">
                <div class="d-flex" id="dvWmntext">
                    <label style="width: 20%;"> WBENC Certification Number </label>
                    <div>
                        <div style="margin-bottom: 2%;">
                            <input id="wbenc_certification_num" name="wbenc_certification_num" type="text" bs-tooltip
                                title="Please Enter WBENC Certification Number"
                                formControlName="wbenc_certification_num"
                                title="Please Enter WBENC Certification Number">
                        </div>

                        <div
                            *ngIf="regulatoryForm.controls['wbenc_certification_num'].invalid && regulatoryForm.controls['wbenc_certification_num'].touched">
                            <div class="alert alert-danger "
                                *ngIf="regulatoryForm.controls['wbenc_certification_num'].errors.required">
                                Enter WBENC Certification Number
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex" id="dvWmnDatetext">
                    <label style="width: 20%;"> WBENC Expiration Date </label>
                    <div>
                        <div style="margin-bottom: 2%;">
                            <input id="wbenc_cert_expiry_date" name="wbenc_cert_expiry_date" type="date" bs-tooltip
                                title="Please Enter WBENC Cert Expiration Date"
                                formControlName="wbenc_cert_expiry_date">
                        </div>
                        <div
                            *ngIf="regulatoryForm.controls['wbenc_cert_expiry_date'].invalid && regulatoryForm.controls['wbenc_cert_expiry_date'].touched">
                            <div class="alert alert-danger "
                                *ngIf="regulatoryForm.controls['wbenc_cert_expiry_date'].errors.required">
                                Enter Valid Expiration Date
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>
            <div class="d-flex">
                <label style="width: 45%;" class="required"> Are you a certified Woman Owned Business (certified by WBE
                    Canada)?
                </label>
                <input name="wbec" id="wbec" type="radio" (click)="radioValueChange('wbec','Y')" value="Y"
                    formControlName="wbec"><span>Yes</span>
                &nbsp;
                <input name="wbec" id="wbecNo" type="radio" (click)="radioValueChange('wbec','N')" value="N"
                    formControlName="wbec"><span>No</span>
            </div>
            <div style="width: 55%;display: flex;justify-content: flex-end;"
                *ngIf="regulatoryForm.controls['wbec'].invalid && regulatoryForm.controls['wbec'].touched">
                <div  class="alert alert-danger "
                    *ngIf="regulatoryForm.controls['wbec'].errors.required">
                    Please select Yes or No
                </div>
            </div>
            <div *ngIf="regulatoryForm.controls.wbec.value == 'Y'">
                <div class="d-flex" id="dvMintext">
                    <label style="width: 20%;"> WBE Certification Number</label>
                    <div>
                        <div style="margin-bottom: 2%;">
                            <input id="wbec_certification_num" type="text" bs-tooltip
                                title="Please Enter WBE Certification Number" formControlName="wbec_certification_num">
                        </div>

                        <div
                            *ngIf="regulatoryForm.controls['wbec_certification_num'].invalid && regulatoryForm.controls['wbec_certification_num'].touched">
                            <div class="alert alert-danger "
                                *ngIf="regulatoryForm.controls['wbec_certification_num'].errors.required">
                                Enter WBE Certification Number
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex" id="dvMinDatetext">
                    <label style="width: 20%;"> WBE Expiration Date </label>
                    <div>
                        <div style="margin-bottom: 2%;">
                            <input id="wbec_cert_expiry_date" name="wbec_cert_expiry_date" type="date" bs-tooltip
                                formControlName="wbec_cert_expiry_date" title="Please Enter WBE Cert Expiration Date">
                        </div>

                        <div
                            *ngIf="regulatoryForm.controls['wbec_cert_expiry_date'].invalid && regulatoryForm.controls['wbec_cert_expiry_date'].touched">
                            <div class="alert alert-danger "
                                *ngIf="regulatoryForm.controls['wbec_cert_expiry_date'].errors.required">
                                Enter Valid Expiration Date
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>

            <div class="d-flex">
                <label style="width: 45%;" class="required"> Are you a certified Aboriginal or minority Owned Business
                    (certified by CAMSC)?
                </label>
                <input name="aboriginal_owned" id="aboriginal_owned" type="radio"
                    (click)="radioValueChange('aboriginal_owned','Y')" value="Y"
                    formControlName="aboriginal_owned"><span>Yes</span>
                &nbsp;
                <input name="aboriginal_owned" id="aboriginal_ownedNo" type="radio"
                    (click)="radioValueChange('aboriginal_owned','N')" value="N"
                    formControlName="aboriginal_owned"><span>No</span>
            </div>
            <div style="width: 55%;display: flex;justify-content: flex-end;"
                *ngIf="regulatoryForm.controls['aboriginal_owned'].invalid && regulatoryForm.controls['aboriginal_owned'].touched">
                <div  class="alert alert-danger "
                    *ngIf="regulatoryForm.controls['aboriginal_owned'].errors.required">
                    Please select Yes or No
                </div>
            </div>
            <div *ngIf="regulatoryForm.controls.aboriginal_owned.value == 'Y'">
                <div class="d-flex" id="dvMintext">
                    <label style="width: 20%;"> CAMSC Certification Number</label>
                    <div>
                        <div style="margin-bottom: 2%;">
                            <input id="camsc_certification_num" type="text" bs-tooltip
                                formControlName="camsc_certification_num"
                                title="Please Enter CAMSC Certification Number">
                        </div>

                        <div
                            *ngIf="regulatoryForm.controls['camsc_certification_num'].invalid && regulatoryForm.controls['camsc_certification_num'].touched">
                            <div class="alert alert-danger "
                                *ngIf="regulatoryForm.controls['camsc_certification_num'].errors.required">
                                Enter CAMSC Certification Number
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex" id="dvMinDatetext">
                    <label style="width: 20%;"> CAMSC Expiration Date </label>
                    <div>
                        <div style="margin-bottom: 2%;">
                            <input id="camsc_cert_expiry_date" name="camsc_cert_expiry_date" type="date" bs-tooltip
                                formControlName="camsc_cert_expiry_date"
                                title="Please Enter CAMSC Cert Expiration Date">
                        </div>

                        <div
                            *ngIf="regulatoryForm.controls['camsc_cert_expiry_date'].invalid && regulatoryForm.controls['camsc_cert_expiry_date'].touched">
                            <div class="alert alert-danger "
                                *ngIf="regulatoryForm.controls['camsc_cert_expiry_date'].errors.required">
                                Enter Valid Expiration Date
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>

    </form>
</div>