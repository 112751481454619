
<div class="col-md-12" style="overflow:hidden;" >
    <br>
    <form [formGroup]="productForm">
        <div class="d-flex justify-content-end">

            <div class="col-md-6">
                <p class="justify-content-center" style="text-align: end; ">Please select the commodity
                    that is closest to what your
                    company provides:</p>
                <div class="d-flex justify-content-end align" style="padding-top: 5%;">
                    <label style="width: 30%;" class="required">Primary (Required) </label>
                   
                    <ng-multiselect-dropdown formControlName="primary_product_id" class=" dropdown1" [settings]="dropdownSettings" [data]="productList"></ng-multiselect-dropdown>

                </div>
                <div class="d-flex "
                    *ngIf="productForm.controls['primary_product_id'].invalid && productForm.controls['primary_product_id'].touched">
                    <label style="width: 52%;"></label>
                    <div style="width: 30%;"
                        *ngIf="productForm.controls['primary_product_id'].invalid && productForm.controls['primary_product_id'].touched"
                        class="alert alert-danger ">
                        <div *ngIf="productForm.controls['primary_product_id'].errors.required">
                            Please Select Primary
                        </div>
                    </div>
                </div>
                <br>
                <div class="d-flex justify-content-end align">
                   <label style="width: 30%;">Secondary (Optional) </label>                   
                    <ng-multiselect-dropdown formControlName="secondary_product_id" class=" dropdown1" [settings]="dropdownSettings" [data]="productList"></ng-multiselect-dropdown>

                </div>
                <br>
                <div class="d-flex justify-content-end align">
                    <label style="width: 30%;">Tertiary (Optional) </label>
                  
                    <ng-multiselect-dropdown  formControlName="tertiary_product_id" class=" dropdown1"[settings]="dropdownSettings" [data]="productList"></ng-multiselect-dropdown>

                </div>
            </div>
            <div class="col-md-6" id="btn-notes">
                <p class="d-flex justify-content-start">(Optional) Notes:</p>
                <div style="padding-top: 5%;">
                    <input type="text" id="primary_product_notes" class="form-control"
                        formControlName="primary_product_notes" bs-tooltip title="Please Enter (optional) Primary Product Notes">
                </div>
                <br>
                <div>
                    <input type="text" id="secondary_product_notes" class="form-control"
                        formControlName="secondary_product_notes" bs-tooltip
                        title="Please Enter (optional) Secondary Product Notes">
                </div>
                <br>
                <div>
                    <input type="text" id="tertiary_product_notes" class="form-control"
                        formControlName="tertiary_product_notes" bs-tooltip title="Please Enter (optional) Tertiary Product Notes">
                </div>
            </div>
        </div>
    </form>
</div>