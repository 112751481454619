// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  envName: 'local',
  production: false,
  apiBaseUrl: 'https://dev.psi.toyotasupplier.com',
  logger: {
    level: 'DEBUG'
  },

  apiUrl: {
    // lookupUrl: 'https://jj28jmj5vf.execute-api.us-west-2.amazonaws.com/dev',
    lookupUrl: 'https://api.dev.psi.toyotasupplier.com/serviceLookup',

    // registerURL:'https://jyit5gcw4d.execute-api.us-west-2.amazonaws.com/dev',
    registerURL:'https://api.dev.psi.toyotasupplier.com/serviceRegister',

  },
};
