import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  apiBaseUrl = this.environment.apiUrl.registerURL;
  apiVersion = '/api/v1';
  serviceUrl = this.apiBaseUrl;
  locallookup = "http://localhost:3000";
  // apiUrl = this.locallookup + this.apiVersion;
  apiUrl = this.apiBaseUrl + this.apiVersion;
  constructor(private http: HttpClient, private environment: EnvironmentConfiguration) { }

  public getCompanyInfo(duns_number): Observable<any> {
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'params': `{"duns_number":"${duns_number}"}`
      })
    };
    return this.http.get<any>(this.apiUrl + '/register/companyinfo', httpCustomOptions);
  }


  insertCompanyInfo(data) {
    let model = JSON.stringify(data);
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Authorization': `${this.service.userToken}`,
        // 'X-Api-Key': `${this.apikey}`,
        'params': model
      })
    };

    return this.http.post<any>(this.apiUrl + '/register/companyinfo', {}, httpCustomOptions);
  }

  public getSalesInfo(duns_number): Observable<any> {
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'params': `{"duns_number":"${duns_number}"}`
      })
    };
    return this.http.get<any>(this.apiUrl + '/register/salesinfo', httpCustomOptions);
  }


  public getProductGroup(duns_number): Observable<any> {
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'params': `{"duns_number":"${duns_number}"}`
      })
    };
    return this.http.get<any>(this.apiUrl + '/register/productinfo', httpCustomOptions);
  }
  insertSalesInfo(data) {
    let model = JSON.stringify(data);
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Authorization': `${this.service.userToken}`,
        // 'X-Api-Key': `${this.apikey}`,
        'params': model
      })
    };

    return this.http.post<any>(this.apiUrl + '/register/salesinfo', {}, httpCustomOptions);
  }


  insertProductGroup(data) {
    let model = JSON.stringify(data);
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Authorization': `${this.service.userToken}`,
        // 'X-Api-Key': `${this.apikey}`,
        'params': model
      })
    };

    return this.http.post<any>(this.apiUrl + '/register/productinfo', model, httpCustomOptions);
  }
  public getSQDGroup(duns_number): Observable<any> {
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'params': `{"duns_number":"${duns_number}"}`
      })
    };
    return this.http.get<any>(this.apiUrl + '/register/sqd', httpCustomOptions);
  }
  insertSQDGroup(data) {
    let model = JSON.stringify(data);
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Authorization': `${this.service.userToken}`,
        // 'X-Api-Key': `${this.apikey}`,
        'params': model
      })
    };

    return this.http.post<any>(this.apiUrl + '/register/sqd', model, httpCustomOptions);
  }
  insertRegulator(data) {
    let model = JSON.stringify(data);
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Authorization': `${this.service.userToken}`,
        // 'X-Api-Key': `${this.apikey}`,
        'params': model
      })
    };

    return this.http.post<any>(this.apiUrl + '/register/regulatoryinfo', model, httpCustomOptions);
  }
  public getRegulatoryInfo(duns_number): Observable<any> {
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'params': `{"duns_number":"${duns_number}"}`
      })
    };
    return this.http.get<any>(this.apiUrl + '/register/regulatoryinfo', httpCustomOptions);
  }
  uploadComapnyGetSignedUrl(file_name, duns_number): Observable<any> {
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization':`${this.userToken}`,
        'params': `{"duns_number":"${duns_number}","file_name":"${file_name}"}`,
      })
    };
    return this.http.post(
      this.apiUrl + '/register/getsignedurlcompanyprofile', {}, httpCustomOptions
    );
  }
  uploadCompanyInUrl(url, file) {
    return this.http.put<any>(url, file, { observe: 'response' });
  }
  uplodFileToS3(params): Observable<any> {
    let jsonPayload = JSON.stringify(params);
    const headers = {
      headers: new HttpHeaders({
        params: jsonPayload,
      })
    };
    return this.http.post(
      this.apiUrl + '/register/uploadfile',
      {},
      headers
    );
  }
  public getUploadedFileInfo(duns_number): Observable<any> {
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'params': `{"duns_number":"${duns_number}"}`
      })
    };
    return this.http.get<any>(this.apiUrl + '/register/lastupload', httpCustomOptions);
  }
  getPreviousFileInfo(duns_number): Observable<any[]> {
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization':`${this.userToken}`,
        'params': `{"duns_number":"${duns_number}"}`,
      })
    };

    return this.http.get<any[]>(this.apiUrl + '/register/lastupload', httpCustomOptions);
  }
  getPreviousFile(duns_number): Observable<any[]> {
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization':`${this.userToken}`,
        'params': `{"duns_number":"${duns_number}"}`,
      })
    };

    return this.http.get<any[]>(this.apiUrl + '/register/companyfile/' + duns_number, httpCustomOptions);
  }
  supplierRegister(email, duns_number) {
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Authorization': `${this.service.userToken}`,
        // 'X-Api-Key': `${this.apikey}`,
        // 'params': `{"duns_number":"${duns_number}","mailId":"${email}"}`
      })
    };
    let modal = `{"mailId":"${email}","duns_number":"${duns_number}"}`;
    return this.http.post<any>(this.apiUrl + '/register/otp', JSON.parse(modal), httpCustomOptions);
  }
  submitMail(email, duns_number) {
    let modal = `{"duns_number":"${duns_number}","mailId":"${email}"}`
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Authorization': `${this.service.userToken}`,
        // 'X-Api-Key': `${this.apikey}`,
        //  'params': `{"duns_number":"${duns_number}","email":"${email}"}`
      })
    };

    return this.http.post<any>(this.apiUrl + '/register/submissionmail', JSON.parse(modal), httpCustomOptions);
  }
  public generateLoginOTP(duns_number, email): Observable<any> {
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'params': `{"duns_number":"${duns_number}"}`,
      })
    };
    let modal = `{"duns_number":"${duns_number}","mailId":"${email}"}`;
    
    return this.http.post<any>(this.apiUrl + '/register/otplogin', JSON.parse(modal), httpCustomOptions);
  }

}
