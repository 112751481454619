import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  EnvironmentConfiguration, Environments,
  environments
} from './services/environment.configuration';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
  ],
  exports: [

  ],
  providers: [
    {
      provide: Environments,
      useValue: environments,
    },
    EnvironmentConfiguration
  ]
})
export class EnvironmentModule { }
