import { Component, OnInit, ɵLocaleDataIndex } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { timers } from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { LookupService } from '../services/lookup.service';
import { RegistrationService } from '../services/registration.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: any;
  duns_no = '';
  otp = '';
  err_duns: string;
  err_otp: boolean = false;
  err_otp_lenth: boolean = false;
  error: boolean = false;
  err_wrong_otp: boolean = false;
  userErr: boolean = false;
  err_email: boolean = false;
  validEmail: boolean = false;
  showOtp: any;
  error_email: boolean;
  successMessage: string = '';
  failureAlert: boolean = false;
  successAlert: boolean = false;
  failureMessage: string;

  constructor(private formBuilder: UntypedFormBuilder, private route: Router, private lookupService: LookupService, private registerService: RegistrationService, private ngxSpinner: NgxSpinnerService) {
    this.loginForm = this.formBuilder.group({
      duns_no: ['', Validators.required],
      email: ['', Validators.required],
      otp: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    sessionStorage.clear();
    localStorage.clear();
  }

  login() {

    this.error = false;
    this.validEmail = false;
    this.err_email = false;
    if (!this.duns_no) {
      this.err_duns = "Please Enter Your DUNS Number.";
    }
    else if (this.duns_no.toString().length != 9) {
      this.err_duns = 'DUNS Number should be 9 digits.'
    }

    else {
      this.err_duns = '';
    }
    if (!this.loginForm.controls.email.value) {
      this.err_email = true;
    }
    else if (this.loginForm.controls.email.errors) {
      this.validEmail = true;
    }


    if (this.otp == '') {
      this.err_otp = true;

    }
    else {
      this.err_otp = false
    }
    this.err_wrong_otp = false;
    this.err_otp_lenth = false;

    debugger;
    if (this.loginForm.valid && this.err_duns == '' && !this.err_otp_lenth && !this.userErr && !this.validEmail && !this.err_email) {
      this.ngxSpinner.show();
      this.lookupService.getUserInfo(parseInt(this.loginForm.controls.duns_no.value), this.loginForm.controls.otp.value).subscribe(resp => {
        if (resp.body == 'User registration not completed.' || resp.body == 'User registration not completed' || resp.body == "User does not exist" || resp.body == "User does not exist.") {
          this.userErr = true;
        } else if (resp.body == 'duns_number or otp is wrong') {
          this.error = true;
        }
        else if (resp.body == 'otp is wrong') {
          this.err_wrong_otp = true;
        }
        else if (resp.statusCode == 200) {

          if (resp.body == 'duns_number or otp is wrong') {
            this.error = true;
          }
          else if (resp.body == 'otp is wrong') {
            this.err_wrong_otp = true;
          } else if (resp.body == 'User registration not completed' || resp.body == "User does not exist") {
            this.userErr = true;
          }

          else if (resp.body.length > 0) {
            sessionStorage.setItem('duns_number', resp.body[0].duns_number);
            sessionStorage.setItem('email', resp.body[0].email);
            this.loginForm.controls.duns_no.setValue('');
            this.loginForm.controls.otp.setValue('');

            this.route.navigate(['/registration']);
          }
        } else {
          this.error = true;
        }
        this.ngxSpinner.hide()
      }, (error) => {
        console.log(error);
        this.ngxSpinner.hide();
        this.failureAlert = true;
        this.failureMessage = "Something went wrong. Please try after sometime.";
        setTimeout(() => {
          this.failureAlert = false;
          this.successMessage = '';
          this.failureMessage = '';
          this.successAlert = false;
        }, 3000);
      })




    }
  }
  onNumberValidate(event, len) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) || event.key === "-" || event.target.value.length === len ||
      event.key.toUpperCase() === "E" || event.key === '.') {
      return false;
    }
    return true;

  }


  async downloadFile() {
    this.ngxSpinner.show();
    try {
      const data = await this.lookupService.getSampleData().toPromise();
      const a = document.createElement('a');
      a.href = data["body"]["signedURL"];
      a.target = '_blank';
      a.rel = 'noreferrer noopener';
      if (a) {
        window.open(a.href, '_blank');
      }
    } catch (error) {
      console.log(error);
      this.failureAlert = true;
      this.failureMessage = "Something went wrong. Please try after sometime.";
      setTimeout(() => {
        this.failureAlert = false;
        this.successMessage = '';
        this.failureMessage = '';
        this.successAlert = false;
      }, 3000);
    } finally {
      this.ngxSpinner.hide();
    }
  }
  onGenerateOTP() {
    this.err_duns = '';

    if (!this.duns_no) {
      this.err_duns = "Please Enter Your DUNS Number.";
    }
    else if (this.duns_no.toString().length != 9) {
      this.err_duns = 'DUNS Number should be 9 digits.'
    }

    else {
      this.err_duns = '';
    }
    this.err_email = false;
    this.validEmail = false;
    if (!this.loginForm.controls.email.value) {
      this.err_email = true;
    }
    else if (this.loginForm.controls.email.errors) {
      this.validEmail = true;
    }

    this.showOtp = false;
    this.userErr = false;
    this.error_email = false;
    this.successMessage = '';
    this.successAlert = false;
    this.failureAlert = false;
    if (this.loginForm.controls.duns_no.value != '' && this.loginForm.controls.email.value != '' && !this.validEmail && !this.err_email && this.err_duns == '') {
      this.ngxSpinner.show();
      this.registerService.generateLoginOTP(parseInt(this.loginForm.controls.duns_no.value), this.loginForm.controls.email.value).subscribe(data => {

        if (data.body == 'User registration not completed' || data.body == 'User registration not completed.' || data.body == "User does not exist" || data.body == "User does not exist.") {
          this.userErr = true;
        } else if (data.body == 'Mail sent successfully') {
          this.showOtp = true;
          this.successAlert = true;
          this.successMessage = 'Email send successfully to ' + this.loginForm.controls.email.value;
        } else if (data.body == 'Invalid Email') {
          this.error_email = true;
        }
        else if (data.body == 'Failed to send mail') {
          this.failureAlert = true;
          this.failureMessage = 'Failed to send mail. Please try after sometime.';
        }
        else {
          this.failureAlert = true;
          this.failureMessage = "Failed to send mail. Please try after sometime.";

        }
        setTimeout(() => {
          this.failureAlert = false;
          this.successMessage = '';
          this.failureMessage = '';
          this.successAlert = false;
        }, 5000);
        this.ngxSpinner.hide();
      }, (error) => {
        console.log(error);
        this.ngxSpinner.hide();
        this.failureAlert = true;
        this.failureMessage = "Something went wrong. Please try after sometime.";
        setTimeout(() => {
          this.failureAlert = false;
          this.successMessage = '';
          this.failureMessage = '';
          this.successAlert = false;
        }, 3000);
      })
    }
  }
}