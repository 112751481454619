import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  apiBaseUrl = this.environment.apiUrl.lookupUrl;
  apiVersion = '/api/v1';

  serviceUrl = this.apiBaseUrl;
//  locallookup = "http://localhost:3000";
  lookupUrl = this.apiBaseUrl + this.apiVersion;
  // lookupUrl = this.locallookup + this.apiVersion;
  constructor(private http: HttpClient, private environment: EnvironmentConfiguration) { }

  getSampleData(): Observable<any[]> {
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization':`${this.userToken}`,
      })
    };

    return this.http.get<any[]>(this.lookupUrl + '/lookup/sample', httpCustomOptions);
  }

  public getUserInfo(duns_number, otp): Observable<any> {
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'params': `{"duns_number":"${duns_number}","otp":"${otp}"}`,
      })
    };
    let result = this.http.get<any>(this.lookupUrl + '/lookup/login', httpCustomOptions);
    return result;
  }

  getProductList(): Observable<any[]> {
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization':`${this.userToken}`,
      })
    };

    return this.http.get<any[]>(this.lookupUrl + '/lookup/productlist', httpCustomOptions);
  }

  verifyOTPRegister(otp,duns_number){
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization':`${this.userToken}`,
        'params':`{"duns_number":"${duns_number}","otp":"${otp}"}`
      })
    };

    return this.http.get<any[]>(this.lookupUrl + '/lookup/register', httpCustomOptions);
  }

}
