<div class="col-md-12">
    <div class="alert-success" *ngIf="successAlert">
        <img style="margin-right: 1em;" src="../../assets/img/checkCircle.png" alt="image not found">

        <span>{{this.successMessage}}</span>
    </div>
   
</div>
<div class="alert-failure" *ngIf="failureAlert">
    <img style="margin-right: 1em;" src="../../assets/img/errCircle.png" alt="image not found">
    <span>{{this.failureMessage}}</span>
</div>
<div class="col-md-12 container" style="text-align: center;padding-top: 5%;">
    <div>
        <a class="a-tag" (click)="downloadFile()">Click here to view a sample Company Profile</a>
    </div>
    <br>
    <label>Please upload your company profile below</label>
    <br>
    <form class="dropdowntilealign rmvBorder form-inline">
        <br> <br>

        <div class><input style="align-items: center;" type="file" class="file-input" (change)="onFileSelected($event)"
                #fileUpload></div>

    </form>
    <a class="a-tag" (click)="downloadPreviousFile()" *ngIf="preFile">Download Previously Uploaded profile</a>


    <br>
    <br>
    <p>*Note: File size may not exceed 2MB (Only .xls, .xlsx, .doc, .docx, .pdf, .ppt and .pptx extensions are allowed.)
    </p>
    <div>
        <label>Please make sure you check over your application information closely before submitting.</label>
        <p>Please click on the steps shown on the top navigation and review the information entered.</p>
    </div>
    <br>
    <div>
        <label style="color: #971518; display:none;"> Processing your Application, Please wait until you see the Success
            Page, Thanks for your patience...</label>
    </div>
</div>