import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgControl, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegistrationComponent } from '../registration/registration.component';
import { RegistrationService } from '../services/registration.service';

@Component({
  selector: 'app-company-info',
  templateUrl: './company_info.component.html',
  styleUrls: ['./company_info.component.scss']
})
export class ComapnyInfoComponent implements OnInit {
  headquarterInfo: UntypedFormGroup;
  salesInfo: UntypedFormGroup;
  duns_number = sessionStorage.getItem('duns_number');
  email = sessionStorage.getItem('email');
  company_info: any;
  sales_info: any;
  errEmail: boolean = false;
  registration: RegistrationComponent;
  successMessage: string = '';
  failureAlert: boolean = false;
  successAlert: boolean = false;
  failureMessage: string;
  dropdownSettings: IDropdownSettings = {};
  dropdownCountrySettings: IDropdownSettings = {};
  checkboxList = [{ name: ' North Eastern US', value: ' North Eastern US', isSelected: false },
  { name: 'Western U.S.', value: 'Western US', isSelected: false },
  { name: 'Midwestern U.S.', value: 'Midwestern US', isSelected: false },
  { name: 'Southern U.S.', value: 'Southern US', isSelected: false }]

  checkboxList2 = [{ name: 'North Eastern Mexico', value: 'North Eastern Mexico', isSelected: false },
  { name: 'Western Mexico', value: 'Western Mexico', isSelected: false },
  { name: 'Midwestern Mexico', value: 'Midwestern Mexico', isSelected: false },
  { name: 'Southern Mexico', value: 'Southern Mexico', isSelected: false }]

  checkboxList3 = [{ name: 'Yukon', value: 'Yukon', isSelected: false },
  { name: 'Northwest Territories', value: 'Northwest Territories', isSelected: false },
  { name: 'Nunavut', value: 'Nunavut', isSelected: false },
  { name: 'British Columbia', value: 'British Columbia' }, { name: 'Alberta', value: 'Alberta', isSelected: false },
  { name: 'Sask.', value: 'Sask', isSelected: false },
  { name: 'Manitoba', value: 'Manitoba', isSelected: false },
  { name: 'Ontario', value: 'Ontario', isSelected: false },
  { name: 'Quebec', value: 'Quebec', isSelected: false },
  { name: 'PEI', value: 'PEI', isSelected: false }, { name: 'NB', value: 'NB', isSelected: false }
    , { name: 'NS', value: 'NS', isSelected: false }]

  checkboxList4 = [{ name: 'Europe', value: 'Europe', isSelected: false },
  { name: 'Asia', value: 'Asia', isSelected: false },
  { name: 'South America', value: 'South America', isSelected: false },
  { name: 'Africa', value: 'Africa', isSelected: false },
  { name: 'Australia', value: 'Australia', isSelected: false }]

  USList = ['Alaska', 'Alabama', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'U.S. Virgin Islands', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming', 'Outside U.S.']
  caList = ['Ontario', 'Quebec', 'British Columbia', 'Alberta', 'Manitoba', 'Saskatchewan', 'Nova Scotia', 'New Brunswick', 'Newfoundland and Labrador', 'Prince Edward Island', 'Northwest Territories', 'Nunavut', 'Yukon']
  mxList = ['Aguascalientes', 'Baja California', 'Baja California Sur', 'Campeche', 'Colima', 'Coahuila De Zaragoza', 'Chiapas', 'Chihuahua', 'Distrito Federal', 'Durango', 'Guerrero', 'Guanajuato', 'Hidalgo', 'Jalisco', 'Mexico', 'Michoacan De Ocampo', 'Morelos', 'Nayarit', 'Nuevo Leon', 'Oaxaca', 'Puebla', 'Queretaro De Arteaga', 'Quintana Roo', 'Sinaloa', 'San Luis Potosi', 'Sonora', 'Tabasco', 'Tamaulipas', 'Tlaxcala', 'Veracruz Llave', 'Yucatan', 'Zacatecas']

  countryList = [

    'Afghanistan',
    'Albania',
    'Algeria',
    'American Samoa',
    'Angola',
    'Anguilla',
    'Antartica',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Ashmore and Cartier Island',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bosnia and Herzegovina',
    'Botswana',
    'Brazil',
    'British Virgin Islands',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burma',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cape Verde',
    'Cayman Islands',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Christmas Island',
    'Clipperton Island',
    'Cocos (Keeling) Islands',
    'Colombia',
    'Comoros',
    'Congo, Democratic Republic of the',
    'Congo, Republic of the',
    'Cook Islands',
    'Costa Rica',
    " Cote d'Ivoire ",
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czeck Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Europa Island',
    'Falkland Islands (Islas Malvinas)',
    'Faroe Islands',
    'Fiji',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia',
    'French Southern and Antarctic Lands',
    'Gabon',
    'Gambia, The',
    'Gaza Strip',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Glorioso Islands',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Heard Island and McDonald Islands',
    'Holy See (Vatican City)',
    'Honduras',
    'Hong Kong',
    'Howland Island',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Ireland, Northern',
    'Israel',
    'Italy',
    'Jamaica',
    'Jan Mayen',
    'Japan',
    'Jarvis Island',
    'Jersey',
    'Johnston Atoll',
    'Jordan',
    'Juan de Nova Island',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Korea, North',
    'Korea, South',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macau',
    'Macedonia, Former Yugoslav Republic of',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Man, Isle of',
    'Marshall Islands',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Mexico',
    'Micronesia, Federated States of',
    'Midway Islands',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'Netherlands Antilles',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Niue',
    'Norfolk Island',
    'Northern Mariana Islands',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Pitcaim Islands',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Reunion',
    'Romainia',
    'Russia',
    'Rwanda',
    'Saint Helena',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Pierre and Miquelon',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Scotland',
    'Senegal',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Georgia and South Sandwich Islands',
    'Spain',
    'Spratly Islands',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Svalbard',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Tobago',
    'Toga',
    'Tokelau',
    'Tonga',
    'Trinidad',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'Uruguay',
    'United States',
    'Uzbekistan',
    'Vanuatu',
    'Venezuela',
    'Vietnam',
    'Virgin Islands',
    'Wales',
    'Wallis and Futuna',
    'West Bank',
    'Western Sahara',
    'Yemen',
    'Yugoslavia',
    'Zambia',
    'Zimbabwe']
  statusValue: boolean;
  locationErr: boolean;
  stateList: any;
  submitValue: boolean = false;
  state: any;
  stateListOther: any = [];
  countryListOther: any = [];
  constructor(private formBuilder: UntypedFormBuilder, private registrationService: RegistrationService, private ngxSpinner: NgxSpinnerService) {
    this.headquarterInfo = this.formBuilder.group({});
    this.headquarterInfo = new UntypedFormGroup({
      'companyName': new UntypedFormControl('', [Validators.required]),
      'address1': new UntypedFormControl('', [Validators.required]),
      'address2': new UntypedFormControl('', []),
      'city': new UntypedFormControl('', [Validators.required]),
      'state': new UntypedFormControl('', [Validators.required]),
      'country': new UntypedFormControl('', [Validators.required]),
      'postalCode': new UntypedFormControl('', [Validators.required]),
      'taxID': new UntypedFormControl('', [Validators.required]),
      'otherState': new UntypedFormControl('', []),
    });
    this.salesInfo = this.formBuilder.group({});
    this.salesInfo = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      title: new UntypedFormControl('', [Validators.required]),
      phoneNo: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required]),
      confirmEmail: new UntypedFormControl('', [Validators.required]),
      website: new UntypedFormControl('', [Validators.required])
    });
    this.countryList.forEach(item => {
      let country: any = {};
      country.country = item;
      this.countryListOther.push(country)
    });
  }

  ngOnInit(): void {
    this.ngxSpinner.show();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'state',
      textField: 'state',
      enableCheckAll: false,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      limitSelection: 1,
      searchPlaceholderText: 'Select'
    };
    this.dropdownCountrySettings = {
      singleSelection: false,
      idField: 'country',
      textField: 'country',
      enableCheckAll: false,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      limitSelection: 1,
      searchPlaceholderText: 'Select'
    };
   
    this.registrationService.getCompanyInfo(this.duns_number).subscribe(data => {
    
      this.ngxSpinner.hide();
      if (data.statusCode == 200) {
        this.company_info = data.body[0];
        this.headquarterInfo.controls.companyName.setValue(data.body[0].company_name);
        this.headquarterInfo.controls.address1.setValue(data.body[0].address_line1);

        this.headquarterInfo.controls.address2.setValue(data.body[0].address_line2);
        this.headquarterInfo.controls.city.setValue(data.body[0].city);

        this.headquarterInfo.controls.country.setValue([{ country: data.body[0].country }]);
        this.headquarterInfo.controls.postalCode.setValue(data.body[0].zipcode);
        this.headquarterInfo.controls.taxID.setValue(data.body[0].taxid);
        this.countryChange();
        //location
        let ary = data.body[0].c_location.split(',');
        this.checkboxList.filter(o1 => !ary.some(o2 => {
          if (o1.value === o2) { o1.isSelected = true; }
        }));
        this.checkboxList2.filter(o1 => !ary.some(o2 => {
          if (o1.value === o2) { o1.isSelected = true; }
        }));
        this.checkboxList3.filter(o1 => !ary.some(o2 => {
          if (o1.value === o2) { o1.isSelected = true; }
        }));
        this.checkboxList4.filter(o1 => !ary.some(o2 => {
          if (o1.value === o2) { o1.isSelected = true; }
        }
        ));

        setTimeout(() => {
          this.state = data.body[0].state;
          this.changeState(data.body[0].state);
          this.headquarterInfo.controls.state.setValue([{ state: data.body[0].state }]);
          this.headquarterInfo.controls.otherState.setValue(data.body[0].other_state);
        }, 100);

      }

    });
    this.registrationService.getSalesInfo(this.duns_number).subscribe(data => {
      if (data.statusCode == 200) {
        this.salesInfo.controls.title.setValue(data.body[0].title);
        this.salesInfo.controls.name.setValue(data.body[0].name);
        this.salesInfo.controls.phoneNo.setValue(data.body[0].phone);
        this.salesInfo.controls.email.setValue(data.body[0].email);
        this.salesInfo.controls.confirmEmail.setValue(data.body[0].email);
        this.salesInfo.controls.website.setValue(data.body[0].website_url);
      }
    }, (error) => {
      console.log(error);
      this.ngxSpinner.hide();
      this.failureAlert = true;
      this.failureMessage = "Something went wrong. Please try after sometime.";
      setTimeout(() => {
        this.failureAlert = false;
        this.failureMessage = '';
      }, 3000);
    })
  }
  checkEmail() {
    this.errEmail = false;
    if (this.salesInfo.controls.confirmEmail.value != '' && this.salesInfo.controls.email.value != '') {
      if (this.salesInfo.controls.email.value != this.salesInfo.controls.confirmEmail.value) {
        this.errEmail = true;
      }
    }
  }
  saveOrUpdate() {
    this.submitValue = false;
   
    this.headquarterInfo.markAllAsTouched();
    this.salesInfo.markAllAsTouched();
    let aryList = this.checkboxList.filter(item => item.isSelected == true);
    let aryList1 = this.checkboxList2.filter(item => item.isSelected == true);
    let aryList2 = this.checkboxList3.filter(item => item.isSelected == true);
    let aryList3 = this.checkboxList4.filter(item => item.isSelected == true);
    if (aryList.length == 0 && aryList1.length == 0 && aryList2.length == 0 && aryList3.length == 0) {
      this.locationErr = true;
    } else {
      this.locationErr = false;
    }
    let stringList = aryList.map((el) => el.value + ',').toString();
    stringList += aryList1.map((el) => el.value + ',').toString();
    stringList += aryList2.map((el) => el.value + ',').toString();
    stringList += aryList3.map((el) => el.value + ',').toString();
    if (this.headquarterInfo.valid && this.salesInfo.valid && !this.errEmail && !this.locationErr && this.headquarterInfo.controls.state.value.length > 0 &&  this.headquarterInfo.controls.country.value.length>0) {
      var today = new Date();
      let data: any = {};
      data.c_location = stringList + '';
      this.ngxSpinner.show();
      data.duns_number = this.duns_number;
      data.company_name = this.headquarterInfo.controls.companyName.value;
      data.address_line1 = this.headquarterInfo.controls.address1.value;

      data.address_line2 = this.headquarterInfo.controls.address2.value;
      data.city = this.headquarterInfo.controls.city.value;
      data.state = this.headquarterInfo.controls.state.value[0].state;
      data.other_state = this.headquarterInfo.controls.otherState.value
      data.country = this.headquarterInfo.controls.country.value[0].country;
      data.zipcode = this.headquarterInfo.controls.postalCode.value;
      data.tax_id = this.headquarterInfo.controls.taxID.value;
      data.created_by = this.email;
      let val = this.saveSalesInfo();
      this.registrationService.insertCompanyInfo(data).subscribe(data => {
        var today = new Date();
        this.submitValue = true;


      }, (error) => {
        console.log(error);
        this.ngxSpinner.hide();
        this.submitValue = false;
        this.failureAlert = true;
        this.failureMessage = "Something went wrong. Please try after sometime.";
        setTimeout(() => {
          this.failureAlert = false;
          this.failureMessage = '';
        }, 3000);
      })

      return true;
    }
    else {
      return false;
    }

  }

  saveSalesInfo() {
    let data: any = {};
    this.ngxSpinner.show();
    data.duns_number = this.duns_number;
    data.title = this.salesInfo.controls.title.value
    data.name = this.salesInfo.controls.name.value;
    data.phone = this.salesInfo.controls.phoneNo.value;
    data.email = this.salesInfo.controls.email.value;
    data.website_url = this.salesInfo.controls.website.value;
    data.created_by = this.email;

    this.registrationService.insertSalesInfo(data).subscribe(data => {
      this.submitValue = true;
      let today = new Date();
      if (data["statusCode"] === 200 || data["statusCode"] === 201) {
        // this.ngOnInit();

      }
      setTimeout(() => {
        //this.ngxSpinner.hide();
      }, 200);
      //   this.registration.checkStatue(0);


    }, (error) => {
      console.log(error);
      this.ngxSpinner.hide();
      this.failureAlert = true;
      this.failureMessage = "Something went wrong. Please try after sometime.";
      setTimeout(() => {
        this.failureAlert = false;
        this.failureMessage = '';
      }, 3000);
    })
  }

  changeState(val) {
    this.state = '';
    this.headquarterInfo.controls.otherState.setValue('');
    this.headquarterInfo.controls.otherState.setValidators([]);
    if (this.headquarterInfo.controls.state.value != '') {
      if (this.headquarterInfo.controls.state.value.length > 0)
        this.state = this.headquarterInfo.controls.state.value[0].state;
    }
    if (this.state == 'Other' || val == 'Other') {
      this.state = 'Other';
      this.headquarterInfo.controls.otherState.setValidators([Validators.required]);

    }


    if (this.state) {
      let state = [];
      this.stateListOther.forEach(item => {
        if (item.state === this.state) {
          state.push(item);
        }
        this.headquarterInfo.controls.state.setValue(state);
      });
    }

    this.headquarterInfo.get('otherState').updateValueAndValidity();
  }
  countryChange() {

    this.stateList = [];
    this.stateListOther = [];
    this.headquarterInfo.controls.state.setValue('');
    if(this.headquarterInfo.controls.country.value.length>0){
    if (this.headquarterInfo.controls.country.value[0].country == 'United States') {

      this.stateList = this.USList;
    } else if (this.headquarterInfo.controls.country.value[0].country == 'Canada') {

      this.stateList = this.caList;
    } else if (this.headquarterInfo.controls.country.value[0].country == 'Mexico') {

      this.stateList = this.mxList;
    }
    else {
      this.stateList = ['Other'];
      this.state = 'Other';
      this.headquarterInfo.controls.state.setValue([{ state: 'Other' }]);

    }
  }

    this.stateList.forEach(item => {
      let state: any = {};
      state.state = item;
      this.stateListOther.push(state)
    });
    this.changeState('');
  }
  onInputChange(value) {
    if (value) {
      var x = value.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      var y = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');

      this.salesInfo.controls.phoneNo.setValue(y);
    } else {

      this.salesInfo.controls.phoneNo.setValue('');
    }
    ///   this.model.valueAccessor.writeValue(y);
    // this.rawChange.emit(rawValue); 
  }
}
