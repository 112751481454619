import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ComapnyInfoComponent } from '../company_info/company_info.component';
import { CompanyProfileComponent } from '../company_profile/company_profile.component';
import { ProductGroupComponent } from '../product_Group/product_Group.component';
import { RegulatoryComponent } from '../regulatory/regulatory.component';
import { SafetyQualityDeliveryComponent } from '../safetyQualityDelivery/safetyQualityDelivery.component';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  loginForm: any;
  selectedIndex: any;
  selectedTabIndex: number = 0;
  duns_number = sessionStorage.getItem('duns_number');
  email = sessionStorage.getItem('email');
  @ViewChild(ComapnyInfoComponent) company_info: ComapnyInfoComponent;
  @ViewChild(ProductGroupComponent) product_group: ProductGroupComponent;
  @ViewChild(CompanyProfileComponent) company_profile: CompanyProfileComponent;
  @ViewChild(SafetyQualityDeliveryComponent) sqd: SafetyQualityDeliveryComponent;
  @ViewChild(RegulatoryComponent) requlatory: RegulatoryComponent;
  disable: boolean = false;
  constructor(private formBuilder: UntypedFormBuilder, private route: Router, private ngxSpinner: NgxSpinnerService) {

  }


  ngOnInit(): void {

    this.selectedIndex = 0;
    if (this.duns_number == null || this.duns_number == '' || this.duns_number == undefined) {
      this.route.navigate(['/login']);
    } else {
      if (localStorage.getItem('tabIndex')) {
        this.selectedIndex = localStorage.getItem('tabIndex');
      } else
        this.selectedIndex = 0;
      sessionStorage.setItem('selectIndex', this.selectedIndex);
    }
  }
  disable_tab: any = false;
  previous_tab_index: any = 0;
  onTabChanged(event) {
    this.disable = false;
    localStorage.setItem('tabIndex', event.index);
    debugger
    if (event.index <= sessionStorage.getItem('selectIndex')) {
      this.selectedIndex = event.index;
      return false;
    } else {
      this.selectedIndex = sessionStorage.getItem('selectIndex');
    }
  }
  nextPage() {
    debugger;
    let resultValue = false;
    if (this.selectedIndex == 0) {
      this.company_info.saveOrUpdate();
      setTimeout(() => {
        this.selectedIndex = 0;
        resultValue = this.company_info.submitValue;
      }, 2300);

    } else if (this.selectedIndex == 1) {
      this.product_group.saveProduct();
      setTimeout(() => {
        this.selectedIndex = 1;
        resultValue = this.product_group.submitValue;

      }, 2300);

    }
    else if (this.selectedIndex == 2) {
      this.sqd.saveSQD();
      setTimeout(() => {
        this.selectedIndex = 2;
        resultValue = this.sqd.submitValue;
      }, 2300);

    }
    else if (this.selectedIndex == 3) {
      this.requlatory.onSave();
      setTimeout(() => {
        this.selectedIndex = 3;
        resultValue = this.requlatory.submitValue;
      }, 2300);

    }
    else if (this.selectedIndex == 4) {
      this.selectedIndex = 4;

    }
    setTimeout(() => {
      this.ngxSpinner.hide();
      if (resultValue == true) {
        this.selectedIndex = this.selectedIndex + 1;
        sessionStorage.setItem('selectIndex', this.selectedIndex);
      }
      else {
        console.log('Please try again later')
      }
    }, 2500);
  }


  prePage() {
    this.selectedIndex = this.selectedIndex - 1;
  }
  finishURL() {

    if (this.disable == false) {
      this.disable = true;
      setTimeout(() => {

        this.route.navigate(['/submit']);
      }, 2000);
    }

  }
  checkStatue(val) {
    this.selectedIndex = val + 1;
    sessionStorage.setItem('selectIndex', this.selectedIndex);

  }
}
