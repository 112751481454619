import { Component, OnInit, HostListener } from '@angular/core';
import { Constants } from '../../constants';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})

export class NavigationBarComponent {
  title = 'PSI';
  name: any = 'psi Local';


  constructor(private router: Router,  private spinner: NgxSpinnerService , private formBuilder: UntypedFormBuilder) {
    


}


  ngOnInit(): void {

  }
 
  logOut() {
  //  this.authentication.logout();
  }
}
