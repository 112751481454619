import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationBarComponent } from './core/navigation-bar/navigation-bar.component';
import { FooterComponent } from './core/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {LoginComponent} from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import{ComapnyInfoComponent} from './company_info/company_info.component';
import {ProductGroupComponent} from './product_Group/product_Group.component';
import {SafetyQualityDeliveryComponent} from './safetyQualityDelivery/safetyQualityDelivery.component';
import {RegulatoryComponent} from './regulatory/regulatory.component';
import {CompanyProfileComponent} from './company_profile/company_profile.component';
import { Environments, environments, EnvironmentConfiguration } from './environment/services/environment.configuration';
import { EnvironmentModule } from './environment/environment.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import {SubmitComponent} from './submit/submit.component';
import { NewUserComponent } from './new-user/new-user.component';
import  {DecimaNumberDirective} from './services/decimalNumber.directive'
import { CommonModule } from '@angular/common';
@NgModule({ declarations: [
        AppComponent,
        NavigationBarComponent,
        FooterComponent,
        LoginComponent,
        RegistrationComponent,
        ComapnyInfoComponent,
        ProductGroupComponent,
        SafetyQualityDeliveryComponent,
        RegulatoryComponent,
        CompanyProfileComponent,
        SubmitComponent,
        NewUserComponent,
        DecimaNumberDirective
    ],
    bootstrap: [AppComponent], 
    
    imports: [NgMultiSelectDropDownModule,
        NgxSpinnerModule,
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        MatRippleModule,
        MatButtonModule,
        MatMenuModule,
        MatTooltipModule,
        MatSelectModule,
        MatToolbarModule,
        MatListModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatTabsModule,
        BrowserAnimationsModule,
        NgbModule,
        EnvironmentModule], providers: [
        {
            provide: Environments,
            useValue: environments
        },
        EnvironmentConfiguration,
        provideHttpClient(withInterceptorsFromDi()),
        
    ] })
export class AppModule {
  constructor(private injector: Injector,private environment: EnvironmentConfiguration) {
  //  console.log("environemnt app module--", this.environment.envName)
   
  }
}