import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
//Comment out line number 6 to 13 for enabling console.log

console.log = function (): void { };

console.debug = function (): void { };
console.warn = function (): void { };

console.info = function (): void { };
if (environment.production) {
  enableProdMode();
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
