import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LookupService } from '../services/lookup.service';
import { RegistrationService } from '../services/registration.service';
@Component({
  selector: 'app-companyProfile',
  templateUrl: './company_profile.component.html',
  styleUrls: ['./company_profile.component.scss']
})
export class CompanyProfileComponent implements OnInit {
  duns_number = sessionStorage.getItem('duns_number');
  email = sessionStorage.getItem('email');
  fileName = '';
  time_stamp = '';
  preFile: boolean = false;
  successMessage: string = '';
  failureAlert: boolean = false;
  successAlert: boolean = false;
  failureMessage: string;
  submitValue: boolean;

  constructor(private formBuilder: UntypedFormBuilder, private route: Router, private lookupService: LookupService, private ngxSpinner: NgxSpinnerService, private registrationService: RegistrationService) {

  }

  ngOnInit(): void {
    this.preFile = false;
    this.submitValue = true;
    this.registrationService.getUploadedFileInfo(this.duns_number).subscribe(data => {
      if (data.statusCode == 200) {
        if (data.body.length > 0) {
          this.preFile = true;
        }
      }
    });
  }
  downloadFile() {
    this.ngxSpinner.show();
    this.lookupService.getSampleData().subscribe(data => {
      const a = document.createElement('a');
      a.href = data["body"]["signedURL"];
      a.target = '_blank';
      a.rel = 'noreferrer noopener'
      if (a) {
        window.open(a.href, '_blank');
      }
      this.ngxSpinner.hide();
    });

  }

  onFileSelected(event: any) {
    debugger;
    this.submitValue = false;
    const file: File = event.target.files[0];
    this.fileName = event.target.files[0].name;
   
    this.successMessage = '';
    this.successAlert = false;
    this.failureAlert = false;
 
    if(file.size>=2097152){
      this.failureAlert = true;
      this.failureMessage = 'Maximum allowed size 2mb.';
    }
let fileExe = file.name.split('.');
let filename = this.fileName.substr(0,this.fileName.lastIndexOf('.'));
var parts = filename.replace(/([^\w ]|_)/g, '_');
this.fileName = parts+'.'+fileExe[fileExe.length-1];

if(fileExe[fileExe.length-1] !='xls' && fileExe[fileExe.length-1] != 'xlsx' && fileExe[fileExe.length-1] != 'doc' &&
fileExe[fileExe.length-1] !='docx'  && fileExe[fileExe.length-1] !='pdf' && fileExe[fileExe.length-1] != 'ppt' && fileExe[fileExe.length-1] != 'pptx'){
  this.failureAlert = true;
  this.failureMessage = 'Only .xls, .xlsx, .doc, .docx, .pdf, .ppt and .pptx extensions are allowed ';
}
    if( !this.failureAlert){
      this.ngxSpinner.show();
    this.registrationService.uploadComapnyGetSignedUrl(this.fileName,this.duns_number).subscribe(data => {
   this.successMessage = 'File has been successfully uploaded';
      this.registrationService.uploadCompanyInUrl(data.body.URL, file).subscribe(data2 => {
        if (data2.status == 200) {
          let params = {
            "uploaded_by": this.email,
            "file_name": this.fileName,
            "duns_number": this.duns_number
          }
          this.registrationService.uplodFileToS3(params).subscribe(data => {
            this.submitValue = true;
            if (data.body) {
              this.ngxSpinner.hide();
              this.successMessage = 'File has been successfully uploaded';
              this.successAlert = true;
              setTimeout(() => {
                this.successMessage = '';
                this.successAlert = false;
            
              }, 3000);
             
            }
            else {
                 
            }
          }, (error)=>{
            console.log(error)
            this.errorMsg();
          })
          this.ngxSpinner.hide();
        }
      }, (error)=>{
        console.log(error)
        this.errorMsg();
      })

    }, (error)=>{
      console.log(error)
     this.errorMsg();
    })

    }
    setTimeout(() => {
      this.failureAlert = false;
      this.failureMessage = '';
  
    }, 4000);
  }
  errorMsg(){
    this.submitValue = false;
    this.failureAlert = true;
    this.submitValue = false;
    this.ngxSpinner.hide();
    this.failureMessage = "Something went wrong. Please try after sometime.";
    setTimeout(() => {
      this.failureAlert = false;
      this.failureMessage = '';

    }, 3000);
  }
  downloadPreviousFile() {

    debugger;
    this.ngxSpinner.show();
    this.registrationService.getPreviousFileInfo(this.duns_number).subscribe(data1 => {
      this.registrationService.getPreviousFile(this.duns_number).subscribe(data => {
        debugger;
        const a = document.createElement('a');
        a.href = data["body"]["URL"];
        a.target = '_blank';
        a.rel = 'noreferrer noopener'
        if (a) {
          window.open(a.href, '_blank');
        }
        this.ngxSpinner.hide();
      });
    });

  }
}
