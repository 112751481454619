
export const environment = {
  envName: 'prod',
  production: true,
  hostname: 'psi.toyotasupplier.com',
  apiBaseUrl: 'https://psi.toyotasupplier.com',
  logger: {
    level: 'INFO'
  },
  xApiKey: '',
  apiUrl: {
    lookupUrl: 'https://api.psi.toyotasupplier.com/serviceLookup',
    registerURL: 'https://api.psi.toyotasupplier.com/serviceRegister'
    // lookupUrl: 'https://f2t50l9ba2.execute-api.us-east-1.amazonaws.com/prod',
    // registerURL: 'https://o19i5tlq7k.execute-api.us-east-1.amazonaws.com/prod'
  }
};
