<div class="col-md-12">
    <div class="alert-success" *ngIf="successAlert">
        <img style="margin-right: 1em;" src="../../assets/img/checkCircle.png" alt="image not found">
        <span>{{this.successMessage}}</span>
    </div>
    <div class="alert-failure" *ngIf="failureAlert">
        <img style="margin-right: 1em;" src="../../assets/img/errCircle.png" alt="image not found">
        <span>{{this.failureMessage}}</span>
    </div>

    <div class="col-md-12 center">
        <h2 class="lgn"> <strong>Sign Up!</strong></h2>
        <br>
        <form [formGroup]="registrationform" id="btn-login-conatiner">
            <div class="form-group">
                <label class="header" for="duns_no">DUNS Number</label>
                <input [maxLength]="9"  *ngIf="!otpEnable" type="text" bs-tooltip title="Please Enter DUNS Number" id="duns_no"
                    formControlName="duns_no" [(ngModel)]="duns_no" (keypress)="onNumberValidate($event,9)">
                <input *ngIf="otpEnable" type="text" bs-tooltip title="Please Enter DUNS Number" id="duns_no"
                    formControlName="duns_no" [(ngModel)]="duns_no" readonly>
                <div *ngIf="duns_no_error">
                    <label></label> <label class="alert alert-danger">{{duns_no_error}}</label>
                </div>

            </div>
            <div class="form-group">
                <label for="Email" class="header">Email</label>
                <input *ngIf="!otpEnable" type="email" id="email" formControlName="email" [(ngModel)]="email" bs-tooltip
                    title="Please Enter Email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9._-]+\.[A-Za-z]{2,4}$">
                <input *ngIf="otpEnable" type="email" id="email" formControlName="email" [(ngModel)]="email" bs-tooltip
                    title="Please Enter Email" readonly>
                <div *ngIf="email_error">
                    <label></label>
                    <label class="alert alert-danger">{{email_error}}</label>
                </div>
            </div>
            <div *ngIf="validation_error">
                <label class="header" ></label>
                <label class="alert alert-danger">{{validation_error}}</label></div>
            <div class="d-flex center form-group">
                <div class="header_otp">
                    <label class="header" *ngIf="otpEnable">One Time Password</label>
                </div>
                <div>
                    <input [maxLength]="6" *ngIf="otpEnable" type="text" bs-tooltip title="Please Enter One Time Password" id="passwrd"
                        formControlName="otp" [(ngModel)]="otp" (keypress)="onNumberValidate($event,9)">

                    <br>
                    <button class="buttonPSL" (click)="onGenerateOTP()" *ngIf="!otpEnable">Generate One Time
                        Password</button>
                    <a *ngIf="otpEnable" style=" color: #971518;" (click)="onGenerateOTP()"> Resend One Time
                        Password</a>
                    <br>

                    <label *ngIf="otpErr" class="alert alert-danger">Please Enter One Time Password</label>
                    <label *ngIf="otpVerifyErr" class="alert alert-danger">Please Provide Correct One Time Password</label>
                </div>
            </div>
            <br>
            <label *ngIf="!otpEnable && otpErr" class="alert alert-danger">Please generate One Time Password</label>

            <br>
            <br>
            <div *ngIf="otpEnable">
                <button class="buttonPSLogin" (click)="onVerifyOTP()">Register</button>
            </div>
        </form>

    </div>

</div>