<div class="col-md-12">
    <div id="title-submit" class="d-flex">
        <div>
            <div class="text-center">
                <strong>SUCCESS</strong>

            </div>
            <br>
            <div>
                <p>Your application has been submitted. Thank you for your interest in becoming a Toyota Supplier.
                </p>
            </div>
        </div>
    </div>

    <br>


    <div id="btn-contact" class="padding-div" style="font-size: 10px;">

        <p>©2023 Toyota Motor Engineering & Manufacturing North America </p>
   
    </div>
</div>